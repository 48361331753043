import { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatRepoName } from "../helpers";
import { Criteria, Step } from "../types";
import { useChecklistContext } from "./ChecklistContext";

interface CriteriaContextType {
  selectedStep: Step | null;
  setSelectedStep: (step: Step | null) => void;
  handleSelectCriteria: (criteriaId: string) => void;
  criteriaData: Criteria | null;
  setCriteriaData: (data: Criteria | null) => void;
  criteriaChecksInProgress: boolean;
  setCriteriaChecksInProgress: (status: boolean) => void;
}

const CriteriaContext = createContext<CriteriaContextType>({
  selectedStep: null,
  setSelectedStep: () => {},
  handleSelectCriteria: () => {},
  criteriaData: null,
  setCriteriaData: () => {},
  criteriaChecksInProgress: false,
  setCriteriaChecksInProgress: () => {}
});

export const useCriteriaContext = () => useContext(CriteriaContext);

export const CriteriaContextProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { selectedRepo } = useChecklistContext();
  const [selectedStep, setSelectedStep] = useState<Step | null>(null);
  const [criteriaData, setCriteriaData] = useState<Criteria | null>(null);
  const [criteriaChecksInProgress, setCriteriaChecksInProgress] = useState<boolean>(false);

  const handleSelectCriteria = (criteriaId: string) => {
    if (!/^\d+$/.test(criteriaId)) {
      console.error('Invalid criteria ID format');
      return;
    }
    navigate(`/checklist/${formatRepoName(selectedRepo?.name || '', false)}/check/${criteriaId}`);
  };

  return (
    <CriteriaContext.Provider 
      value={{ 
        selectedStep, 
        setSelectedStep, 
        handleSelectCriteria,
        criteriaData,
        setCriteriaData,
        criteriaChecksInProgress,
        setCriteriaChecksInProgress
      }}
    >
      {children}
    </CriteriaContext.Provider>
  );
};
