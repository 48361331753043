import React, { forwardRef } from "react";
import { ToggleButton, Tooltip, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface SideBarButtonProps {
  id?: string;
  value: any;
  selected: boolean;
  children: React.ReactNode;
  handleClick: (event: React.MouseEvent<HTMLElement>, value: any) => void;
  sx?: any;
  tooltip?: string;
  hidden?: boolean;
  isExpanded?: boolean;
  label?: string;
  alignment?: 'left' | 'center' | 'right';
}

const SideBarButton = forwardRef<
  HTMLButtonElement,
  SideBarButtonProps
>(({ id, selected, value, handleClick, children, sx, tooltip, hidden, isExpanded, label, alignment = 'left' }, ref) => {
  const theme = useTheme();
  
  if (hidden) {
    return null; 
  }

  const getJustifyContent = () => {
    if (!isExpanded) return 'center';
    switch(alignment) {
      case 'right':
        return 'flex-end';
      case 'center':
        return 'center';
      default:
        return 'flex-start';
    }
  };

  if (isExpanded) {
    return (
      <Tooltip title={tooltip || ""} placement="right">
        <Box
          onClick={(e) => handleClick(e, value)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
            width: '100%',
            justifyContent: getJustifyContent(),
            pl: alignment !== 'right' ? 0.5 : 0,
            pr: alignment === 'right' ? 0.5 : 0,
            borderRadius: 1,
          }}
        >
          <ToggleButton
            value={value}
            id={id}
            ref={ref}
            sx={{
              width: 35,
              height: 35,
              border: '1px solid',
              borderColor: theme.palette.divider,
              backgroundColor: `${theme.palette.background.paper} !important`,
              pointerEvents: 'none',
              ...sx,
            }}
            color="primary"
            selected={selected}
            hidden={hidden}
          >
            {children}
          </ToggleButton>
          {label && (
            <Typography
              variant="body2"
              sx={{
                color: 'white',
                whiteSpace: 'nowrap',
                pointerEvents: 'none',
              }}
            >
              {label}
            </Typography>
          )}
        </Box>
      </Tooltip>
    );
  } else {
    const button = (
      <ToggleButton
        value={value}
        id={id}
        ref={ref}
        onClick={(e) => handleClick(e, value)}
        sx={{
          width: 35,
          height: 35,
          border: '1px solid',
          borderColor: theme.palette.divider,
          backgroundColor: `${theme.palette.background.paper} !important`,
          pointerEvents: 'auto',
          ...sx,
        }}
        color="primary"
        selected={selected}
        hidden={hidden}
      >
        {children}
      </ToggleButton>
    );

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          cursor: 'pointer',
          width: '100%',
          justifyContent: getJustifyContent(),
          pl: 0,
          pr: 0,
          borderRadius: 1,
        }}
      >
        {tooltip ? (
          <Tooltip title={tooltip} placement="right">
            {button}
          </Tooltip>
        ) : (
          button
        )}
      </Box>
    );
  }
});

export default SideBarButton;
