import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    paddingTop: "2rem",
  },
  continueButton: {
    marginTop: "1rem",
    marginRight: "2rem",
  },
});

export default useStyles;
