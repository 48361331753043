import React, { createContext, useContext, useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { FileNode } from '../types';
import {
  getFolderContents,
  getFileContent,
  updateFile,
  createFolder,
  createFile,
  updateFolder,
  deleteFolder,
  deleteFile,
} from '@/services/Blar/Wiki';
import { Context as AppContext } from '@/contexts/ContextProvider';
import { MDXEditorMethods } from "@mdxeditor/editor";

interface WikiContextType {
  structure: FileNode[];
  setStructure: React.Dispatch<React.SetStateAction<FileNode[]>>;
  selectedNode: FileNode | null;
  setSelectedNode: React.Dispatch<React.SetStateAction<FileNode | null>>;
  inputFileContent: string | null;
  setInputFileContent: React.Dispatch<React.SetStateAction<string | null>>;
  outputFileContent: string;
  setOutputFileContent: React.Dispatch<React.SetStateAction<string>>;
  loadingCount: number;
  setLoadingCount: React.Dispatch<React.SetStateAction<number>>;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  handleSaveFile: () => Promise<void>;
  handleRename: (node: FileNode, newName: string) => Promise<void>;
  handleFileClick: (node: FileNode) => Promise<void>;
  parseResponse: (response: any) => FileNode[];
  handleCreateFile: (name: string, parentId: number | null, content: string | null) => Promise<void>;
  handleCreateFolder: (name: string, parentId: number | null) => Promise<void>;
  handleDelete: (node: FileNode) => Promise<void>;
  handleDragStart: (event: React.DragEvent, node: FileNode) => void;
  handleDragOver: (event: React.DragEvent) => void;
  handleDrop: (event: React.DragEvent, parentNode: FileNode) => Promise<void>;
  editorRef: React.RefObject<MDXEditorMethods> | null;
  setEditorRef: (ref: React.RefObject<MDXEditorMethods>) => void;
  updateEditorContent: (content: string) => void;
  wikiHasUnsyncedChanges: boolean;
  setWikiHasUnsyncedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WikiContext = createContext<WikiContextType | null>(null);

interface WikiProviderProps {
  children: React.ReactNode;
}

interface WikiState {
  structure: FileNode[];
  selectedNode: FileNode | null;
  inputFileContent: string | null;
  outputFileContent: string;
  loadingCount: number;
  title: string;
  editorRef: React.RefObject<MDXEditorMethods> | null;
  wikiHasUnsyncedChanges: boolean;
}

export const WikiProvider: React.FC<WikiProviderProps> = ({ children }) => {
  const [structure, setStructure] = useState<WikiState['structure']>([]);
  const [selectedNode, setSelectedNode] = useState<WikiState['selectedNode']>(null);
  const [inputFileContent, setInputFileContent] = useState<WikiState['inputFileContent']>(null);
  const [outputFileContent, setOutputFileContent] = useState<WikiState['outputFileContent']>('');
  const [loadingCount, setLoadingCount] = useState<WikiState['loadingCount']>(0);
  const [title, setTitle] = useState<WikiState['title']>('');
  const [editorRef, setEditorRefState] = useState<WikiState['editorRef']>(null);
  const [wikiHasUnsyncedChanges, setWikiHasUnsyncedChanges] = useState<WikiState['wikiHasUnsyncedChanges']>(false);
  const { showMessage } = useContext(AppContext);

  const setEditorRef = useCallback((ref: React.RefObject<MDXEditorMethods>) => {
    setEditorRefState(ref);
  }, []);

  const updateEditorContent = useCallback((content: string) => {
    try {
      const trimmedContent = (content || '').trim();
      setOutputFileContent(trimmedContent);
      
      if (editorRef?.current) {
        editorRef.current.setMarkdown(trimmedContent);
        setWikiHasUnsyncedChanges(true);
      }
    } catch (error) {
      console.error('Failed to update editor content:', error);
      showMessage('error', 'Failed to update editor content');
    }
  }, [editorRef, setWikiHasUnsyncedChanges]);

  useEffect(() => {
    if (editorRef?.current && outputFileContent !== undefined) {
      try {
        editorRef.current.setMarkdown(outputFileContent);
      } catch (error) {
        console.error('Failed to sync editor content:', error);
        showMessage('error', 'Failed to sync editor content');
      }
    }
  }, [editorRef, outputFileContent]);

  const parseResponse = useCallback((response: any): FileNode[] => {
    const folders = response.folders.map((folder: any) => ({
      id: folder.id,
      name: folder.name,
      type: 'folder',
      path: folder.path,
      parent: folder.parent,
      children: [],
    }));

    const files = response.files.map((file: any) => ({
      id: file.id,
      name: file.name,
      type: 'file',
      path: file.path,
      parent: file.folder,
      content: file.content,
    }));

    return [...folders, ...files];
  }, []);

  const handleSaveFile = useCallback(async () => {
    if (!selectedNode || selectedNode.type !== 'file') {
      return;
    }

    setLoadingCount((prev) => prev + 1);
    try {
      // The editor (external library) removes newlines before code fences, so we need to add them back. Weird.
      // This is a hack to fix the issue.
      // If we save the file without the hack, the code fences are all clumped together.
      const currentContentRaw = editorRef?.current?.getMarkdown?.() ?? outputFileContent;
      const currentContent = currentContentRaw.replace(/([^\n])(```)/g, '$1\n$2');

      if (currentContent === undefined) {
        throw new Error('No content available to save');
      }

      const updatePromises = [
        updateFile(selectedNode.id, {
          id: selectedNode.id,
          name: selectedNode.name,
          content: currentContent,
          folder: selectedNode.parent,
        }),
        new Promise<void>((resolve) => {
          setOutputFileContent(currentContent);
          setInputFileContent(currentContent);
          setTitle(selectedNode.name);
          setWikiHasUnsyncedChanges(false);
          resolve();
        })
      ];

      const [response] = await Promise.all(updatePromises);
      showMessage('success', 'File saved successfully');
    } catch (error) {
      console.error('Failed to save file:', error);
      showMessage('error', 'Failed to save file');
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  }, [selectedNode, outputFileContent, setWikiHasUnsyncedChanges, editorRef]);

  const handleRename = useCallback(async (node: FileNode, newName: string) => {
    try {
      setLoadingCount((prev) => prev + 1);
      if (node.type === 'folder') {
        await updateFolder(node.id, {
          name: newName,
          parent: node.parent,
          id: node.id,
          path: node.path,
        });
      } else {
        await updateFile(node.id, {
          name: newName,
          content: node.content || '',
          folder: node.parent,
          id: node.id,
          path: node.path,
        });
      }

      setStructure((prevStructure) => {
        const updateNodeName = (nodes: FileNode[]): FileNode[] => {
          return nodes.map((n) => {
            if (n.id === node.id) {
              return { ...n, name: newName };
            }
            if (n.children) {
              return { ...n, children: updateNodeName(n.children) };
            }
            return n;
          });
        };
        return updateNodeName(prevStructure);
      });

      if (selectedNode && selectedNode.id === node.id) {
        setTitle(newName);
      }

      showMessage('success', 'Renamed successfully');
    } catch (error) {
      showMessage('error', 'Failed to rename');
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  }, [selectedNode, showMessage]);

  const handleFileClick = async (node: FileNode) => {
    setSelectedNode(node);
    setLoadingCount((prev) => prev + 1);
    setInputFileContent(null);
    setOutputFileContent('');
    try {
      const content = await getFileContent(node.id);
      setInputFileContent(content);
      setOutputFileContent(content);
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  };

  const handleCreateFile = useCallback(async (
    name: string, 
    parentId: number | null,
    content: string | null = " "
  ) => {
    try {
      setLoadingCount((prev) => prev + 1);
      const newFile = await createFile(name, parentId, content);
      const newFileNode: FileNode = {
        id: newFile.id,
        name: newFile.name,
        type: 'file',
        parent: newFile.folder,
        path: newFile.path,
      };

      setStructure((prev) => {
        const addFileToStructure = (nodes: FileNode[]): [FileNode[], boolean] => {
          let found = false;
          const updatedNodes = nodes.map((node) => {
            if (node.id === parentId) {
              found = true;
              return {
                ...node,
                children: [...(node.children || []), newFileNode],
              };
            }
            if (node.children) {
              const [updatedChildren, childFound] = addFileToStructure(node.children);
              if (childFound) {
                found = true;
                return { ...node, children: updatedChildren };
              }
            }
            return node;
          });
          return [updatedNodes, found];
        };

        const [updatedStructure, found] = addFileToStructure(prev);
        if (!found) {
          return [...prev, newFileNode];
        }
        return updatedStructure;
      });
      
      setSelectedNode(newFileNode);
      handleFileClick(newFileNode);
      
      showMessage('success', 'File created successfully');
    } catch (error) {
      showMessage('error', 'Failed to create file');
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  }, [handleFileClick]);

  const handleCreateFolder = useCallback(async (name: string, parentId: number | null) => {
    try {
      setLoadingCount((prev) => prev + 1);
      const newFolder = await createFolder(name, parentId);
      const newFolderNode: FileNode = {
        id: newFolder.id,
        name: newFolder.name,
        type: 'folder',
        parent: newFolder.parent,
        path: newFolder.path,
        children: [],
      };

      setStructure((prev) => {
        const addFolderToStructure = (nodes: FileNode[]): [FileNode[], boolean] => {
          let found = false;
          const updatedNodes = nodes.map((node) => {
            if (node.id === parentId) {
              found = true;
              return {
                ...node,
                children: [...(node.children || []), newFolderNode],
              };
            }
            if (node.children) {
              const [updatedChildren, childFound] = addFolderToStructure(node.children);
              if (childFound) {
                found = true;
                return { ...node, children: updatedChildren };
              }
            }
            return node;
          });
          return [updatedNodes, found];
        };

        const [updatedStructure, found] = addFolderToStructure(prev);
        if (!found) {
          return [...prev, newFolderNode];
        }
        return updatedStructure;
      });
      
      showMessage('success', 'Folder created successfully');
    } catch (error) {
      showMessage('error', 'Failed to create folder');
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  }, [showMessage]);

  const handleDelete = useCallback(async (node: FileNode) => {
    try {
      setLoadingCount((prev) => prev + 1);
      if (node.type === 'folder') {
        await deleteFolder(node.id);
      } else {
        await deleteFile(node.id);
      }

      setStructure((prevStructure) => {
        const removeNode = (nodes: FileNode[]): FileNode[] => {
          return nodes.filter((n) => {
            // If this is the node to delete, filter it out
            if (n.id === node.id) return false;
            
            // If this node has children, recursively filter them
            if (n.children) {
              n.children = removeNode(n.children);
            }
            return true;
          });
        };

        return removeNode(prevStructure);
      });

      setSelectedNode(null);
      showMessage('success', 'Deleted successfully');
    } catch (error) {
      showMessage('error', 'Failed to delete');
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  }, [showMessage]);

  const handleDragStart = useCallback((event: React.DragEvent, node: FileNode) => {
    event.dataTransfer.setData("application/json", JSON.stringify(node));
  }, []);

  const handleDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
  }, []);

  const handleDrop = useCallback(async (event: React.DragEvent, parentNode: FileNode) => {
    event.preventDefault();
    const draggedNode = JSON.parse(
      event.dataTransfer.getData("application/json")
    ) as FileNode;
    if (draggedNode.id !== parentNode.id) {
      try {
        setLoadingCount((prev) => prev + 1);
        const updatedNode = { ...draggedNode, parent: parentNode.id };
        if (draggedNode.type === "file") {
          await updateFile(draggedNode.id, {
            name: updatedNode.name,
            content: updatedNode.content || "",
            folder: parentNode.id,
            id: updatedNode.id,
          });
        } else {
          await updateFolder(draggedNode.id, updatedNode);
        }
        setStructure((prev) => {
          const removeOldNode = (nodes: FileNode[]): FileNode[] => {
            return nodes.filter((n) => {
              if (n.id === draggedNode.id) return false;
              if (n.children) {
                n.children = removeOldNode(n.children);
              }
              return true;
            });
          };

          const addToNewParent = (nodes: FileNode[]): FileNode[] => {
            return nodes.map((n) => {
              if (n.id === parentNode.id) {
                return {
                  ...n,
                  children: [...(n.children || []), updatedNode],
                };
              }
              if (n.children) {
                return {
                  ...n,
                  children: addToNewParent(n.children),
                };
              }
              return n;
            });
          };

          const withoutOldNode = removeOldNode(prev);
          return addToNewParent(withoutOldNode);
        });
        showMessage("success", "Moved successfully");
      } catch (error) {
        showMessage("error", "Failed to move item");
      } finally {
        setLoadingCount((prev) => prev - 1);
      }
    }
  }, [showMessage]);

  useEffect(() => {
    const fetchRootFolderContents = async () => {
      setLoadingCount((prev) => prev + 1);
      try {
        const response = await getFolderContents(null);
        const parsedStructure = parseResponse(response);
        setStructure(parsedStructure);
      } finally {
        setLoadingCount((prev) => prev - 1);
      }
    };

    fetchRootFolderContents();
  }, [parseResponse]);

  useEffect(() => {
    if (selectedNode && selectedNode.type === 'file') {
      setTitle(selectedNode.name);
    }
  }, [selectedNode]);

  const value = useMemo(() => ({
    structure,
    setStructure,
    selectedNode,
    setSelectedNode,
    inputFileContent,
    setInputFileContent,
    outputFileContent,
    setOutputFileContent,
    loadingCount,
    setLoadingCount,
    title,
    setTitle,
    handleSaveFile,
    handleRename,
    handleFileClick,
    parseResponse,
    handleCreateFile,
    handleCreateFolder,
    handleDelete,
    handleDragStart,
    handleDragOver,
    handleDrop,
    editorRef,
    setEditorRef,
    updateEditorContent,
    wikiHasUnsyncedChanges,
    setWikiHasUnsyncedChanges,
  }), [
    structure,
    selectedNode,
    inputFileContent,
    outputFileContent,
    loadingCount,
    title,
    handleSaveFile,
    handleRename,
    handleFileClick,
    parseResponse,
    handleCreateFile,
    handleCreateFolder,
    handleDelete,
    handleDragStart,
    handleDragOver,
    handleDrop,
    editorRef,
    setEditorRef,
    updateEditorContent,
    wikiHasUnsyncedChanges,
    setWikiHasUnsyncedChanges,
  ]);


  return <WikiContext.Provider value={value}>{children}</WikiContext.Provider>;
};

export const useWiki = () => {
  const context = useContext(WikiContext);
  if (!context) {
    throw new Error('useWiki must be used within a WikiProvider');
  }
  return context;
}; 