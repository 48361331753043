import SearchBar from "@/components/SearchBar/SearchBar";

interface SearchBarWrapperProps {
  onChange: (value: string) => void;
}

const SearchBarWrapper = ({ onChange }: SearchBarWrapperProps) => {
  return (
    <SearchBar
      placeholder="Search repos..."
      onChange={onChange}
    />
  );
};

export default SearchBarWrapper;
