import { Box, Button, Typography } from "@mui/material";
import { RepoGuidelines } from "../StarterPage";
import { AccordionLayout } from "./AccordionLayout";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../components/Buttons/PrimaryButton";
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

type GuidelinesProps = {
  guidelinesDetails: Array<RepoGuidelines>;
}

const messages = {
  all_setted: "Congratulations! You have already set up guidelines for your projects.",
  not_all_setted: "Make sure to have at least one guideline configured for each repository.",
  no_one_setted: "You don't have any guidelines in our wiki. Come on, let's set up some guidelines."
}

const GuidelinesDetails = ({ guidelinesDetails }: GuidelinesProps) => {
  const navigate = useNavigate();

  const getStatus = () => {
      if (guidelinesDetails.length === 0) {
          return messages.no_one_setted;
      }
      
      const allHaveGuidelines = guidelinesDetails.every(repo => repo.wiki_step_status);
      const noneHaveGuidelines = guidelinesDetails.every(repo => !repo.wiki_step_status);
      
      if (allHaveGuidelines) {
          return "all_setted";
      } else if (noneHaveGuidelines) {
          return "no_one_setted";
      } else {
          return "not_all_setted";
      }
  }

  const getButtonText = () => {
    if (getStatus() === "all_setted") {
      return "Add guidelines";
    } else if (getStatus() === "no_one_setted") {
      return "Let's add some guidelines";
    } else {
      return "Add guidelines";
    }
  }

  const getMessage = () => {
    if (getStatus() === "all_setted") {
      return messages.all_setted;
    } else if (getStatus() === "no_one_setted") {
      return messages.no_one_setted;
    } else {
      return messages.not_all_setted;
    }
  }

  return <>
      <Box sx={{ 
        display: "flex", 
        flexDirection: "row", 
        justifyContent: "space-between", 
        alignItems: "center",
        marginBottom: 2
      }}>
          <Typography variant="body1" textAlign="left" color="white">
              {getMessage()}
          </Typography>
          <PrimaryButton onClick={() => navigate('/wiki')}>
            {getButtonText()}
          </PrimaryButton>
      </Box>
      {guidelinesDetails.map((repo) => (
        <Box key={repo.repo_name} sx={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, gap: 1 }}>
          {repo.wiki_step_status ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
          <Typography key={repo.repo_name} variant="body1" sx={{ color: "white", textAlign: "left", display: "flex", gap: 1, alignItems: "center" }}>
            {repo.repo_name} {repo.wiki_step_status ? "has guidelines in our wiki" : "doesn't have guidelines"}
          </Typography>
        </Box>
      ))}
  </>
}

export const GuidelinesAccordion = ({ guidelinesDetails }: GuidelinesProps) => {
  return (
    <AccordionLayout 
      title="Guidelines" 
      description="Defining coding and architectural guidelines is key to maintaining high-quality projects. Establish them in Blar to streamline PR reviews, from naming conventions to library usage." 
      children={<GuidelinesDetails guidelinesDetails={guidelinesDetails} />}
    />
  )
}