import { Box, Skeleton } from "@mui/material";
import { AccordionLayout } from "../AccordionLayout";

const AccordionSkeleton: React.FC = () => {
  return (
    <Box sx={{ width: "100%" }}>
      {[1, 2, 3].map((index) => (
        <AccordionLayout
          key={index}
          title={<Skeleton width={150} height={32} />}
          description={<Skeleton width="80%" height={24} />}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 2, py: 1 }}>
            <Skeleton width="60%" height={24} />
            <Skeleton width={120} height={36} variant="rounded" />
          </Box>
        </AccordionLayout>
      ))}
    </Box>
  );
};

export default AccordionSkeleton; 