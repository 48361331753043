import { Typography } from "@mui/material";
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box } from '@mui/material';
import CodeBlock from '@/components/CodeBlock/CodeBlock';
import LinkBadge from '@/components/LinkBadge/LinkBadge';

interface RemediateContentProps {
  content: string;
}

const RemediateContent: React.FC<RemediateContentProps> = ({ content }) => {
  return (
    <Box>
      <ReactMarkdown
        components={{
          h1: ({children}) => <Typography variant="h4" gutterBottom component="h1">{children}</Typography>,
          h2: ({children}) => <Typography variant="h5" gutterBottom component="h2">{children}</Typography>,
          h3: ({children}) => <Typography variant="h6" gutterBottom component="h3">{children}</Typography>,
          h4: ({children}) => <Typography variant="subtitle1" gutterBottom component="h4">{children}</Typography>,
          h5: ({children}) => <Typography variant="subtitle2" gutterBottom component="h5">{children}</Typography>,
          h6: ({children}) => <Typography variant="subtitle2" gutterBottom component="h6">{children}</Typography>,
          p: ({children}) => <Typography paragraph component="p">{children}</Typography>,
          ol: ({children}) => (
            <Box component="ol" sx={{ 
              pl: 0,
              my: 1, 
              listStyle: 'none',
              counterReset: 'item',
              '& > li': {
                counterIncrement: 'item',
                display: 'flex',
                alignItems: 'flex-start',
                gap: 2,
                mb: 2,
                '&::before': {
                  content: 'counter(item)',
                  minWidth: '24px',
                  height: '24px',
                  backgroundColor: '#fff',
                  color: '#000',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                }
              }
            }}>
              {children}
            </Box>
          ),
          li: ({children}) => (
            <Box 
              component="li" 
              sx={{ 
                color: '#fff',
                backgroundColor: '#161B22',
                borderRadius: '10px',
                p: 0,
                '& > p': { 
                  m: 0,
                },
              }}
            >
              <Typography>{children}</Typography>
            </Box>
          ),
          a: ({children, href}) => (
            <LinkBadge href={href} label={children as string} />
          ),
          code(props) {
            const { children, className, ...rest } = props;
            const match = /language-(\w+)/.exec(className || "");
            return match ? (
              <CodeBlock language={match[1]}>
                {String(children)}
              </CodeBlock>
            ) : (
              <code
                {...rest}
                className={className}
                style={{
                  textOverflow: "ellipsis",
                  wordBreak: "break-word",
                  whiteSpace: "pre-wrap",
                }}
              >
                {children}
              </code>
            );
          },
        }}
      >
        {content}
      </ReactMarkdown>
    </Box>
  );
};

export default RemediateContent;
