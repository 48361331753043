import { TableRowData } from "@/components/Tables/types";
import { InvitationLink } from "./types";
import CopyableTextField from "@/components/TextField/CopyableTextField";
import { User } from "@/interfaces/ICompany";
import LinkGithubUser from "./components/LinkGithubUserButton";

export function convertUsersToRow(
  data: User[],
  setCreatedObject: (object: number) => void
): TableRowData[] {
  return data.map((user: User) => ({
    _id: { content: user.user_id, type: "hidden" },
    Email: { content: user.email, type: "string" },
    "Date Joined": {
      content: new Date(user.created_at),
      type: "date",
      options: { dateFormat: "dd MMMM yyyy HH:mm:SS" },
    },
    "Github User": user.external_profiles[0]
      ? {
        content: user.external_profiles[0]?.username,
        type: "string",
      }
      : {
        content: (
          <LinkGithubUser
            userId={user.user_id}
            setCreatedObject={setCreatedObject}
          />
        ),
        type: "react",
      },
    "Is Active": { content: user.is_active, type: "boolean" },
  }));
}

export function convertInvitationLinkToRow(
  data: InvitationLink[],
  showMessage: (
    type: "success" | "error" | "info" | "warning",
    message: string
  ) => void,
  baseurl: string
): TableRowData[] {
  return data.map((invitationLink: InvitationLink) => ({
    _id: { content: invitationLink.token, type: "hidden" },
    _token: { content: invitationLink.token, type: "hidden" },
    "Created by": { content: invitationLink.user_email, type: "string" },
    Link: {
      type: "react",
      content: (
        <CopyableTextField
          sx={{ marginBottom: "20px" }}
          text={`${baseurl}/invitation?token=${invitationLink.token}`}
          showMessage={showMessage}
          messageText="Link copied"
        />
      ),
    },
    "Expiration Date": {
      content: new Date(invitationLink.expiry_date),
      type: "date",
      options: { dateFormat: "dd MMMM yyyy HH:mm:SS" },
    },
    Active: {
      content: new Date(invitationLink.expiry_date) > new Date(),
      type: "boolean",
    },
  }));
}
