import { GitUser } from "@/pages/Auth/types/common";
import { blarClient } from "./Client";

interface linkGitUserParams {
  user_id: string;
  external_profile: GitUser;
}

export const list_git_user = async (params: linkGitUserParams) => {
  console.log("Linking GitHub user:", params);
  try {
    const response = await blarClient.request(
      "post",
      "/integrations/git/link_user",
      params
    );
    return response.data.results;
  } catch (error) {
    console.error("Error linking GitHub user:", error);
    throw error;
  }
};
