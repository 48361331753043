import { Box, IconButton, Skeleton, Tab, Tabs } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const RemediateDrawerSkeleton: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
      <Box p={2} flexShrink={0}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Skeleton variant="text" width={200} height={32} />
          <IconButton disabled>
            <CloseIcon />
          </IconButton>
        </Box>
        <Skeleton variant="text" width="80%" height={24} sx={{ mb: 3 }} />
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', flexShrink: 0 }}>
        <Tabs 
          value={0} 
          aria-label="remediation tabs"
          textColor="inherit"
          sx={{
            '& .MuiTab-root': {
              color: 'white',
              opacity: 0.5,
            }
          }}
        >
          <Tab label="Steps" disabled />
          <Tab label="Explanation" disabled />
        </Tabs>
      </Box>

      <Box sx={{ p: 2, height: 'calc(100% - 48px)', overflow: 'auto' }}>
        <Box sx={{ mb: 2 }}>
          {[...Array(4)].map((_, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Skeleton variant="text" width="90%" height={24} />
              <Skeleton variant="text" width="70%" height={20} />
              <Skeleton variant="text" width="80%" height={20} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default RemediateDrawerSkeleton; 