import { TableRowData } from "@/components/Tables/types";
import { Repo, RepoPaginatedResponse } from "@/pages/Settings/components/Repos/types";
import BranchBoxes from "@/pages/Settings/components/Repos/components/BranchBoxes";
import ToggleAgent from "./ToggleAgent";


export function convertReposToRow(data: RepoPaginatedResponse): TableRowData[] {
    return data.results.map((repo: Repo) => ({
        _id: { content: repo.repo_id, type: "hidden" },
        Name: { content: repo.name, type: "string" },
        "GitHub Url": {
            content: repo.url,
            type: "url",
        },
        "Tracked Branches": {
            content: <BranchBoxes mainBranch={repo.main_branch} developmentBranch={repo.development_branch} />,
            type: "react"
        },
        "Debugger Agent": {
            content: <ToggleAgent agentType="debugger_pull_request_review" repoId={repo.repo_id} />,
            type: "react",
        },
        "Optimizer Agent": {
            content: <ToggleAgent agentType="optimizer_pull_request_review" repoId={repo.repo_id} />,
            type: "react",
        },
        "Cybersecurity Agent": {
            content: <ToggleAgent agentType="cybersecurity_pull_request_review" repoId={repo.repo_id} />,
            type: "react",
        },
        "Design Patterns Agent": {
            content: <ToggleAgent agentType="design_patterns_pull_request_review" repoId={repo.repo_id} />,
            type: "react",
        }
    }));
}
