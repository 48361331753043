import { Box, Typography } from "@mui/material";
import { RepoPullRequest } from "../StarterPage";
import { AccordionLayout } from "./AccordionLayout";
import { PrimaryButton } from "@/components/Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";

type PullRequestAccordionProps = {
  pullRequestDetails: Array<RepoPullRequest>;
}

const PullRequestDetails = ({ pullRequestDetails }: PullRequestAccordionProps) => {
  const navigate = useNavigate();
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" gap={2}>
      {pullRequestDetails.length > 0 && pullRequestDetails[0].pull_request_feature_flag ? (
        <Typography textAlign="left" color="white">
          Congrats! You already have the pull request review enabled.
        </Typography>
      ) : (
        <Typography textAlign="left" color="white">
          You don't have the pull request review enabled. 
        </Typography>
      )}
      <PrimaryButton onClick={() => navigate(`/pull-requests`)}>
        <Typography>
          {pullRequestDetails.length > 0 && pullRequestDetails[0].pull_request_feature_flag ? "Go to settings" : "Let's enable it"}
        </Typography>
      </PrimaryButton>
    </Box>
  )
}

export const PullRequestAccordion = ({ pullRequestDetails }: PullRequestAccordionProps) => {
  return (
    <AccordionLayout 
      title="Pull request review" 
      description="Code reviews are essential before deploying to production. Set up automated reviews for your pull requests to catch bugs, improve optimization, enhance cybersecurity, and ensure compliance with your guidelines." 
      children={<PullRequestDetails pullRequestDetails={pullRequestDetails} />}
    />
  )
}