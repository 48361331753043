import { IntegrationCardProps } from "../../pages/Settings/components/Integrations/types";

export const integrations: IntegrationCardProps[] = [
  {
    logo: "/integrations/github.svg",
    name: "GitHub",
    dbName: "github",
    description:
      "GitHub is a code hosting platform for version control and collaboration.",
    category: "code_versioning",
  },
  {
    logo: "/integrations/bitbucket.svg",
    name: "Bitbucket",
    dbName: "bitbucket",
    description: "Bitbucket is a code hosting platform for version control and collaboration.",
    category: "code_versioning",
  },
  {
    logo: "/integrations/new_relic_logo_vertical.svg",
    name: "New Relic",
    dbName: "new_relic",
    description: "New Relic is a performance monitoring tool.",
  },
  {
    logo: "/integrations/SLA-Logo-Mark-COLOR-RGB.png",
    name: "Slack",
    dbName: "slack",
    description: "Slack is a messaging app for teams.",
  },
  {
    logo: "/integrations/sentry-glyph-dark-400x352.svg",
    name: "Sentry",
    dbName: "sentry",
    description: "Sentry is a real-time error tracking tool.",
  }

];
