import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
  PaletteColor,
  Typography,
} from "@mui/material";
import {
  CreateNewFolder,
  Folder,
  FolderOpen,
  InsertDriveFile,
  NoteAdd,
  ArrowBack,
} from "@mui/icons-material";
import { FileNode } from "../types";
import { getFolderContents } from "@/services/Blar/Wiki";
import ContextMenu from "./ContextMenu";
import ConfirmDeleteModal from "@/components/Tables/ConfirmDeleteModal";
import { useWiki } from "../context/WikiContext";

const FileStructure: React.FC = () => {
  const {
    structure,
    setStructure,
    selectedNode,
    setSelectedNode,
    loadingCount,
    setLoadingCount,
    handleFileClick,
    handleCreateFile,
    handleCreateFolder,
    handleDelete,
    handleRename,
    parseResponse,
    handleDragStart,
    handleDragOver,
    handleDrop,
    inputFileContent,
  } = useWiki();

  const [openFolders, setOpenFolders] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [newNodeName, setNewNodeName] = useState<string>("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [repoFolder, setRepoFolder] = useState<FileNode | null>(null);
  const [creatingNode, setCreatingNode] = useState<{
    type: "file" | "folder";
    parentId: number | null;
  } | null>(null);

  const [contextMenu, setContextMenu] = useState<null | HTMLElement>(null);
  const [isCreating, setIsCreating] = useState(false);

  const closeChatRef = useRef(false);

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleRightClick = (event: React.MouseEvent, node: FileNode) => {
    event.preventDefault();
    setSelectedNode(node);
    setContextMenu(event.currentTarget as HTMLElement);
  };

  const handleCreateSubmit = async () => {
    if (creatingNode && newNodeName.trim() && !isCreating) {
      setIsCreating(true);
      try {
        if (creatingNode.type === "folder") {
          await handleCreateFolder(newNodeName.trim(), creatingNode.parentId);
        } else {
          await handleCreateFile(newNodeName.trim(), creatingNode.parentId, "");
        }
      } finally {
        setCreatingNode(null);
        setNewNodeName("");
        setIsCreating(false);
      }
    }
  };

  const handleFolderClose = (e: React.MouseEvent, nodeId: number) => {
    e.stopPropagation();
    setRepoFolder(null);
    setOpenFolders(prev => ({
      ...prev,
      [nodeId]: false
    }));
  };

  const updateNodeInStructure = (structure: FileNode[], updatedNode: FileNode): FileNode[] => {
    return structure.map(item => {
      if (item.id === updatedNode.id) {
        // Return a new copy of the item with updated properties
        return {
          ...item,
          ...updatedNode,
        };
      }
      // If it's a folder, recursively update children
      if (item.children) {
        return {
          ...item,
          children: updateNodeInStructure(item.children, updatedNode)
        };
      }
      return item;
    });
  };

  const handleToggleOnCreate = async (node: FileNode) => {
    if (node.type !== "folder") {
      return;
    }
    
    const isOpen = openFolders[node.id];
    if (!isOpen) {
      setOpenFolders(prev => ({
        ...prev,
        [node.id]: true
      }));

      if (!node.fetched) {
        try {
          setLoadingCount(prev => prev + 1);
          const response = await getFolderContents(node.id);
          const parsedChildren = parseResponse(response);
          const updatedNode = {
            ...node,
            children: parsedChildren,
            fetched: true
          };
          setStructure(prev => updateNodeInStructure(prev, updatedNode));
        } finally {
          setLoadingCount(prev => prev - 1);
        }
      }
    }
  };

  const handleFolderToggle = async (node: FileNode) => {
    const isOpen = openFolders[node.id];
    const newOpenState = !isOpen;
    
    setOpenFolders(prev => ({
      ...prev,
      [node.id]: newOpenState
    }));

    // Only fetch contents if we're opening an unfetched folder
    if (node.type === "folder" && newOpenState && !node.fetched) {
      try {
        setLoadingCount(prev => prev + 1);
        const response = await getFolderContents(node.id);
        const parsedChildren = parseResponse(response);
        const updatedNode = {
          ...node,
          children: parsedChildren,
          fetched: true
        };
        setStructure(prev => updateNodeInStructure(prev, updatedNode));
      } finally {
        setLoadingCount(prev => prev - 1);
      }
    }
  };

  const handleToggle = async (node: FileNode) => {
    setSelectedNode(node);
    
    if (isFolderInRootFolder(node)) {
      setRepoFolder(prev => prev ? null : node);
    }
    
    await handleFolderToggle(node);
  };

  const getRootId = (node: FileNode): number => {
    if (!node.parent) {
      return node.id;
    }
    const findNodeById = (nodes: FileNode[], id: number): FileNode | null => {
      for (const n of nodes) {
        if (n.id === id) return n;
        if (n.children) {
          const found = findNodeById(n.children, id);
          if (found) return found;
        }
      }
      return null;
    };
    const parentId = Number(node.parent);
    const parentNode = findNodeById(structure, parentId);
    if (!parentNode) {
      return parentId;
    }
    return getRootId(parentNode);
  };

  const getParentId = () => {
    if (!selectedNode) return null;
    if (selectedNode.type === "file") {
      const parentId = Number(selectedNode.parent);
      const rootId = getRootId(selectedNode);
      if (parentId === rootId) {
        return null;
      }
      return parentId;
    }
    return Number(selectedNode.id);
  };

  const isInRootFolder = (node: FileNode | null): boolean => {
    if (!node) return false;
    return node.type === 'folder' && !node.parent;
  };

  const isInsideRootFolder = (node: FileNode | null): boolean => {
    if (!node) return false;
    if (node.type === 'file') {
      return !!node.parent && !structure.find(n => n.id === node.parent)?.parent;
    }
    return !!node.parent && !structure.find(n => n.id === node.parent)?.parent;
  };

  const isFolderInRootFolder = (node: FileNode | null): boolean => {
    if (node?.type === 'folder') {
      return node.parent === getRootId(node);
    }
    return false;
  };

  const getFolderIcon = (node: FileNode): React.ReactNode => {
    if (isFolderInRootFolder(node)) {
      if (openFolders[node.id]) {
        return (
          <ArrowBack 
            onClick={(e) => handleFolderClose(e, node.id)} 
          />
        );
      }
      return <img src="/integrations/github-mark-white.svg" alt="GitHub" style={{ width: 24, height: 24 }} />;
    }
    
    return openFolders[node.id] ? <FolderOpen /> : <Folder />;
  };

  const getNodeIcon = (node: FileNode): React.ReactNode => {
    if (node.type === "folder") {
      return getFolderIcon(node);
    }
    return <InsertDriveFile />;
  };

  const shouldRenderNode = (node: FileNode): boolean => {
    if (isFolderInRootFolder(node)) {
      return repoFolder === null || repoFolder.id === node.id;
    }
    return true;
  };

  const renderFileTree = (
    nodes: FileNode[],
    level: number = 0
  ): React.ReactNode => {
    return nodes.map((node) => (
      <React.Fragment key={node.id}>
        {shouldRenderNode(node) && (
          <Tooltip 
            title="Right click for more options"
            placement="right"
            enterDelay={1000}
          >
            <ListItemButton
              data-node-id={node.id}
              draggable
              onDragStart={(event) => handleDragStart(event, node)}
              onDragOver={handleDragOver}
              onDrop={(event) => handleDrop(event, node)}
              onContextMenu={(event) => handleRightClick(event, node)}
              onClick={node.type === "file" ? () => handleFileClick(node) : () => handleToggle(node)}
              sx={{ pl: level * 2 }}
            >
              <ListItemIcon>
                {getNodeIcon(node)}
              </ListItemIcon>
              <ListItemText primary={node.name} />
            </ListItemButton>
          </Tooltip>
        )}
        {node.type === "folder" && (
          <Collapse in={openFolders[node.id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderFileTree(node.children || [], level + 1)}
              {creatingNode && creatingNode.parentId === node.id && (
                <ListItemButton sx={{ pl: (level + 1) * 2 }}>
                  <TextField
                    value={newNodeName}
                    onChange={(e) => setNewNodeName(e.target.value)}
                    onBlur={handleCreateSubmit}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !isCreating) {
                        handleCreateSubmit();
                      } else if (e.key === "Escape") {
                        setCreatingNode(null);
                        setNewNodeName("");
                      }
                    }}
                    disabled={isCreating}
                    autoFocus
                    placeholder={`New ${creatingNode.type}`}
                    size="small"
                  />
                </ListItemButton>
              )}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    ));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (creatingNode) {
        const target = event.target as HTMLElement;
        if (!target.closest('input')) {
          setCreatingNode(null);
          setNewNodeName('');
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [creatingNode]);

  const handleContextMenuRename = () => {
    if (selectedNode) {
      handleClose();
      setCreatingNode(null);
      setNewNodeName(selectedNode.name);
      
      setTimeout(() => {
        const parentElement = document.querySelector(`[data-node-id="${selectedNode.id}"]`);
        if (parentElement) {
          const textElement = parentElement.querySelector('.MuiListItemText-primary') as HTMLElement;
          if (textElement) {
            textElement.setAttribute('contenteditable', 'true');
            textElement.focus();

            const range = document.createRange();
            range.selectNodeContents(textElement);
            const selection = window.getSelection();
            if (selection) {
              selection.removeAllRanges();
              selection.addRange(range);
            }
            
            const handleBlur = async () => {
              const newName = textElement.textContent || '';
              textElement.removeAttribute('contenteditable');
              if (newName && newName !== selectedNode.name) {
                await handleRename(selectedNode, newName);
              }
              textElement.removeEventListener('blur', handleBlur);
              textElement.removeEventListener('keydown', handleKeyDown);
            };

            const handleKeyDown = (e: Event) => {
              const keyEvent = e as KeyboardEvent;
              if (keyEvent.key === 'Enter') {
                e.preventDefault();
                textElement.blur();
              }
              if (keyEvent.key === 'Escape') {
                textElement.textContent = selectedNode.name;
                textElement.blur();
              }
            };

            textElement.addEventListener('blur', handleBlur);
            textElement.addEventListener('keydown', handleKeyDown);
          }
        }
      }, 100);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      sx={{
        background: (theme) =>
          (theme.palette.secondaryBackground as PaletteColor).main,
      }}
      padding={2}
      style={{ cursor: "context-menu" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
          height: 40,
          minHeight: 40,
        }}
      >
        <Typography 
          variant="h6" 
          sx={{ 
            textAlign: "left",
            lineHeight: 1,
          }}
        >
          Wiki
        </Typography>
        {inputFileContent !== null && (
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            minWidth: 80,
            justifyContent: 'flex-end'
          }}>
            {(isInRootFolder(selectedNode) || isInsideRootFolder(selectedNode)) && (
              <>
                <Tooltip title="Create Folder">
                  <IconButton
                    onClick={() =>
                      setCreatingNode({
                        type: "folder",
                        parentId: getParentId(),
                      })
                    }
                  >
                    <CreateNewFolder />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Create File">
                  <IconButton
                    onClick={() =>
                      setCreatingNode({
                        type: "file",
                        parentId: getParentId(),
                      })
                    }
                  >
                    <NoteAdd />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>
        )}
      </Box>
      {loadingCount > 0 && <LinearProgress />}
        <Box
          id="scrollableDiv"
          padding={1}
          sx={{ flexGrow: 1, overflow: "auto", height: "100%" }}
        >
          <List>
            {renderFileTree(structure)}
          </List>
        </Box>
        <ContextMenu
            anchorEl={contextMenu}
            handleClose={handleClose}
            handleCreateFile={() => {
              handleClose();
              handleToggleOnCreate(selectedNode as FileNode);
              setCreatingNode({
                type: "file",
                parentId: getParentId(),
              });
            }}
            handleCreateFolder={() => {
              handleClose();
              handleToggleOnCreate(selectedNode as FileNode);
              setCreatingNode({
                type: "folder",
                parentId: getParentId(),
              });
            }}
            handleRename={handleContextMenuRename}        
            handleDelete={() => setDeleteModalOpen(true)}
            isFolderInRootFolder={isFolderInRootFolder(selectedNode)}
          />
          <ConfirmDeleteModal
            open={deleteModalOpen}
            setOpen={setDeleteModalOpen}
            onConfirm={() => {
              if (selectedNode) {
                handleDelete(selectedNode);
                setDeleteModalOpen(false);
                handleClose();
              }
            }}
        />
    </Box>
  );
};

export default FileStructure;
