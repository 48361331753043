import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { check_integration_exists } from "@/services/Blar/Integrations";
import { list_repos } from "@/services/Blar/Repo_graph";
import handleGithubIntegration, {
  handleRedirectToGitHubApp,
} from "@/pages/Settings/components/Integrations/handleIntegrations/handleGithub";
import {
  handleBitbucketIntegration,
  handleRedirectToBitbucketApp,
} from "@/pages/Settings/components/Integrations/handleIntegrations/handleBitbucket";
import { Context } from "@/contexts/ContextProvider";
import { useSearchParams } from "react-router-dom";
import LoadingTypography from "./components/LoadingTypography";
import ContinueButton from "@/components/Buttons/ContinueButton";
import { IntegrationType } from "@/interfaces/IIntegration";
import useStyles from "./styles/Integrations";
import GitHubUserConfirmation from "./components/GitHubUserConfirmation";
import { fetchUserInfo } from "@/services/Blar/Auth";
import { integrations } from "@/components/Integrations/integrations";
import { IntegrationCardProps } from "@/pages/Settings/components/Integrations/types";
import IconButton from "@mui/material/IconButton";

interface CoreIntegrationProps {
  continueOnboarding: () => void;
}

const CoreIntegrationComponent: React.FC<CoreIntegrationProps> = ({
  continueOnboarding,
}) => {
  const classes = useStyles();
  const { showMessage, userId } = useContext(Context);
  const [searchParams] = useSearchParams();

  // Github states
  const [isGHConnected, setIsGHConnected] = useState<
    boolean | null | "pending"
  >(null);
  const [loadingGithub, setLoadingGithub] = useState(false);
  const [hasGitHubUser, setHasGitHubUser] = useState(false);

  // Bitbucket states
  const [isBBConnected, setIsBBConnected] = useState<
    boolean | null | "pending"
  >(null);
  const [loadingBitbucket, setLoadingBitbucket] = useState(false);
  const [hasBitbucketUser, setHasBitbucketUser] = useState(false);

  const [loadingUser, setLoadingUser] = useState(false);
  const [reloadCheck, setReloadCheck] = useState(false);

  const checkGHIntegrationExists = async (): Promise<boolean | "pending"> => {
    try {
      setLoadingGithub(true);

      const res = await check_integration_exists({
        source: IntegrationType.GITHUB,
      });
      setIsGHConnected(res.data);
    } catch (error) {
      showMessage("error", "Failed to get GitHub integration");
    } finally {
      setLoadingGithub(false);
    }

    return false;
  };

  const checkBBIntegrationExists = async (): Promise<boolean | "pending"> => {
    try {
      setLoadingBitbucket(true);
      const res = await check_integration_exists({
        source: IntegrationType.BITBUCKET,
      });
      setIsBBConnected(res.data);
    } catch (error) {
      showMessage("error", "Failed to get Bitbucket integration");
    } finally {
      setLoadingBitbucket(false);
    }
    return false;
  };

  const loadUserInfo = async () => {
    try {
      setLoadingUser(true);
      const users = await fetchUserInfo();
      if (users.external_profiles.length === 0) {
        setHasGitHubUser(false);
      } else {
        setHasGitHubUser(true);
      }
    } catch (error) {
    } finally {
      setLoadingUser(false);
    }
  };

  useEffect(() => {
    if (isGHConnected) {
      loadUserInfo();
    }
    if (isGHConnected !== null && !isGHConnected) {
      setLoadingGithub(true);
      handleGithubIntegration(showMessage, searchParams)
        .then(() => {
          setReloadCheck(true);
        })
        .finally(() => {
          setLoadingGithub(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGHConnected]);

  useEffect(() => {
    checkGHIntegrationExists();
    checkBBIntegrationExists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadCheck]);

  const userHasCodeVersioningSystem = (): boolean => {
    return isGHConnected === true || isBBConnected === true || false;
  };

  const codeVersioningIntegrations = () => {
    if (isGHConnected === true) {
      return integrations.filter(
        (integration) => integration.dbName === "github"
      );
    } else if (isBBConnected === true) {
      return integrations.filter(
        (integration) => integration.dbName === "bitbucket"
      );
    }

    return integrations.filter(
      (integration: IntegrationCardProps) =>
        integration.category === "code_versioning"
    );
  };

  const oauthRedirect = async (integration: string) => {
    if (integration === "github") {
      handleRedirectToGitHubApp(showMessage);
    } else {
      handleRedirectToBitbucketApp(showMessage);
    }
  };

  const isLoadingIntegration = (integration: string): boolean => {
    if (integration === "github") {
      return loadingGithub;
    } else {
      return loadingBitbucket;
    }
  };

  return (
    <Box className={classes.root}>
      <LoadingTypography
        text="1. Connect to your code versioning system"
        loading={loadingGithub || loadingBitbucket}
        stepCompleted={userHasCodeVersioningSystem()}
      />
      <Grid container spacing={2} className={classes.integrationSection}>
        {codeVersioningIntegrations().map((integration) => (
          <Grid item xs={3} key={integration.dbName}>
            <Tooltip title={integration.name}>
              <IconButton
                key={integration.dbName}
                className={classes.integrationCard}
                onClick={() => oauthRedirect(integration.dbName)}
                disabled={
                  isLoadingIntegration(integration.dbName) ||
                  userHasCodeVersioningSystem()
                }
              >
                <img src={integration.logo} alt={integration.name} />
              </IconButton>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
      {(isGHConnected === "pending" || isBBConnected === "pending") && (
        <Typography variant="body1" color="info" mt={2}>
          Waiting for approval, please ask your organization admin to approve
          the request.
        </Typography>
      )}
      {isGHConnected === true && !hasGitHubUser && (
        <GitHubUserConfirmation
          userId={userId!}
          onConfirmation={() => setHasGitHubUser(true)}
        />
      )}
      <br />
      <ContinueButton
        continueOnboarding={continueOnboarding}
        disabled={!(isGHConnected === true || isBBConnected === true)}
      />
    </Box>
  );
};

export default CoreIntegrationComponent;
