export enum IntegrationType {
  GITHUB = 'github',
  BITBUCKET = 'bitbucket',
  SLACK = 'slack',
  NEW_RELIC = 'new_relic',
  SENTRY = 'sentry',
}

export interface CheckIntegration {
  source: IntegrationType;
}