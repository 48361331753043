import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface PrimaryButtonProps {
  children: React.ReactNode;
  onClick: () => void;
}

export const PrimaryButton = ({ children, onClick }: PrimaryButtonProps) => {
  const theme = useTheme();
  return <Button variant="contained" color="secondary" onClick={onClick}>{children}</Button>
}