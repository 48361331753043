import React, { useState, useEffect, useContext } from "react";
import { Grid, Box, Typography, CircularProgress } from "@mui/material";
import AuthForm from "./AuthForm";
import CoreIntegrationComponent from "./Onboarding/CoreIntegrations";
import AddRepository from "./Onboarding/AddRepository";
import SelectPlan from "./Onboarding/SelectPlan";
import ReadyToGoComponent from "./Onboarding/ReadyToGo";
import WriteGuidelines from "./Onboarding/WriteGuidelines";

import useStyles from "./styles/Onboarding";
import { useNavigate } from "react-router-dom";
import { Context } from "@/contexts/ContextProvider";
import { List } from "lodash";
import { update_company } from "@/services/Blar/Company";
import { useTheme } from "@mui/material/styles";

const onboardingSteps: List<React.FC<any>> = [
  AuthForm,
  SelectPlan,
  CoreIntegrationComponent,
  AddRepository,
  WriteGuidelines,
  ReadyToGoComponent,
];

const Onboarding: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    initialOnboardingStep,
    showMessage,
    setIsOnboarding,
    isEmailVerified,
  } = useContext(Context);
  const onboardingContainerRef = React.useRef<HTMLDivElement>(null);
  const [imageHeight, setImageHeight] = useState<number | null>(null);
  const [onboardingStep, setOnboardingStep] = useState<number | null>(null);
  const theme = useTheme();

  useEffect(() => {
    // Running out of time solution, we can find something better
    // Get step from URL if present
    const searchParams = new URLSearchParams(window.location.search);
    const urlFrom = searchParams.get('from');

    if (urlFrom === 'wiki') {
      setOnboardingStep(4);
    } else if (initialOnboardingStep) {
      setOnboardingStep(initialOnboardingStep);
    } else {
      setOnboardingStep(0);
    }
  }, [initialOnboardingStep]);

  useEffect(() => {
    if (isEmailVerified === false) {
      navigate("/verify-email");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmailVerified]);

  useEffect(() => {
    if (onboardingContainerRef.current) {
      setImageHeight(onboardingContainerRef.current.offsetHeight);
    }
  }, [onboardingContainerRef.current?.offsetHeight, onboardingStep]);

  const continueOnboarding = async () => {
    if (onboardingStep === onboardingSteps.length - 1) {
      // Finish Onboarding
      try {
        await update_company({
          finished_onboarding: true,
        });
        setIsOnboarding(false);
        navigate("/starter");
      } catch (error) {
        showMessage("error", "Network error");
      }
      return;
    }
    try {
      await update_company({ onboarding_step: onboardingStep! + 1 });
      setOnboardingStep(onboardingStep! + 1);
    } catch (error) {
      showMessage("error", "Network error");
    }
  };

  return (
    <Box className={classes.root}>
      {onboardingStep !== null ? (
        <Grid container>
          <Grid
            item
            container
            direction={"column"}
            xs={6}
            sx={{ background: theme.palette.background.paper }}
            className={classes.leftSection}
          >
            <Box className={classes.logo}>
              <img src="/logos/WhiteLogo.svg" alt="Blar" />
              <img src="/logos/BlarLetters.svg" alt="Blar" />
            </Box>
            <Box className={classes.slogan}>
              <Typography
                variant="h3"
                align="right"
                fontSize={40}
                sx={{
                  lineHeight: "50px",
                  paddingTop: "50px",
                }}
              >
                Master Technical Debt
              </Typography>
              <Typography
                variant="h3"
                align="right"
                fontSize={40}
                sx={{
                  lineHeight: "50px",
                }}
              >
                and Legacy Code
              </Typography>
              <Typography
                variant="h3"
                align="right"
                fontSize={40}
                sx={{
                  lineHeight: "50px",
                }}
              >
                with Ease and Confidence
              </Typography>
            </Box>
            <Box ref={onboardingContainerRef} className={classes.stepsBox}>
              {imageHeight && (
                <img
                  style={{ height: imageHeight - 100 }}
                  src={`/onboarding/${onboardingStep + 1}.svg`}
                  alt="Onboarding"
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={6} className={classes.rightSection}>
            <Box height={'100vh'} sx={{ overflow: 'auto'}}>
              {React.createElement(onboardingSteps[onboardingStep], {
                continueOnboarding,
              })}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress size={80} />
        </Box>
      )}
    </Box>
  );
};

export default Onboarding;
