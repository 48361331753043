import { Typography, Box, Divider } from "@mui/material";
import { RepoCriterias, CriteriaStatus } from "../StarterPage";
import { AccordionLayout } from "./AccordionLayout";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../components/Buttons/PrimaryButton";
import { formatRepoName } from "../../Checklist/helpers";
import StatusIcon from "../../Checklist/components/StatusIcon";

type CriteriasAccordionProps = {
  criteriasDetails: Array<RepoCriterias>;
}

const CriteriasDetails = ({ criteriasDetails }: CriteriasAccordionProps) => {
  const navigate = useNavigate();

  return (
    <>
      <Typography textAlign="left" color="white">
        Checklist by repository
      </Typography>
      {criteriasDetails.map((repo: RepoCriterias, index: number) => (
        <>
          {index > 0 && <Divider sx={{ bgcolor: 'rgba(255, 255, 255, 0.12)' }} />}
          <Accordion 
            key={repo.repo_name}
          >
            <AccordionSummary 
              expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
              sx={{ 
                '& .MuiAccordionSummary-content': {
                  mr: 1
                }
              }}
            >
              <Typography>{repo.repo_name} repository has {repo.criterias_statuses.filter(status => status.status === "completed").length}/{repo.criterias_statuses.length} criterias completed </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 2, py: 0 }}>
              {repo.criterias_statuses.map((status: CriteriaStatus) => (
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
                    <StatusIcon status={status.status} />
                    <Typography key={status.criteria__id} variant="body1" sx={{ color: "white", textAlign: "left" }}>
                      {status.criteria__name}
                    </Typography>
                  </Box>
                  <PrimaryButton onClick={() => navigate(`/checklist/${formatRepoName(repo.repo_name)}/check/${status.criteria__id}`)}>
                    <Typography>
                      Check progress
                    </Typography>
                  </PrimaryButton>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </>
      ))}
    </>
  )
}


export const CriteriasAccordion = ({ criteriasDetails }: CriteriasAccordionProps) => {
  return (
    <AccordionLayout 
      title="Best practices" 
      description="Implementing best practices in your projects is crucial. We provide a set of criteria and tests to help you optimize your workflow and manage technical debt. Explore them now." 
      children={<CriteriasDetails criteriasDetails={criteriasDetails} />}
    />
  )
}