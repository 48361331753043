import React, { useContext, useEffect, useState } from "react";
import { Grid, Typography, Chip, Paper, CircularProgress } from "@mui/material";
import AddApiKeyModal from "./AddApiKey";
import { IntegrationCardProps } from "../../pages/Settings/components/Integrations/types";
import { slack_oauth } from "@/services/Blar/Integrations";
import { useSearchParams } from "react-router-dom";
import { Context } from "@/contexts/ContextProvider";
import {
  redirectToGitHubApp,
} from "../../pages/Settings/components/Integrations/handleIntegrations/handleGithub";
import { redirectToSentryApp } from "@/pages/Settings/components/Integrations/handleIntegrations/handleSentry";
import { redirectToBitbucketApp } from "@/pages/Settings/components/Integrations/handleIntegrations/handleBitbucket";

const REDIRECT_TIMEOUT = 2 * 1000;
interface IntegrationProps {
  integration: IntegrationCardProps;
  connected: boolean;
  setCreatedObject: (createdObject: number) => void;
  selectedIntegration?: boolean;
}

const IntegrationCard: React.FC<IntegrationProps> = ({
  integration,
  connected,
  setCreatedObject,
  selectedIntegration,
}) => {
  const { name, description, logo, dbName } = integration;
  const [openAddApiKey, setOpenAddApiKey] = useState(false);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const { showMessage } = useContext(Context);

  useEffect(() => {
    if (selectedIntegration) handleAddIntegrationOauth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIntegration]);

  const handleAddIntegrationOauth = async () => {
    setCreatedObject(Date.now());
  };

  const handleAddIntegration = async () => {
    if (dbName === "slack") {
      slack_oauth()
        .then((response) => {
          window.location.href = response.data.url;
        })
        .catch((error) => {
          console.error(error);
        });

      return;
    } else if (dbName === "github") {
      showMessage("info", "Redirecting to GitHub for authentication");
      setTimeout(() => redirectToGitHubApp(), REDIRECT_TIMEOUT);
      return;
    } else if (dbName === "bitbucket") {
      showMessage("info", "Redirecting to Bitbucket for authentication");
      setTimeout(() => redirectToBitbucketApp(), REDIRECT_TIMEOUT);
      return;
    } else if (dbName === "sentry") {
      showMessage("info", "Redirecting to Sentry for authentication");
      setTimeout(() => redirectToSentryApp(), REDIRECT_TIMEOUT);
      return;
    }
    setOpenAddApiKey(true);
  };

  return (
    <>
      <Paper sx={{ padding: "20px", height: "250px", maxHeight: "250px" }}>
        <Grid container sx={{ mb: 3 }}>
          <Grid item xs={6}>
            <img
              src={logo}
              alt={description}
              width="60"
              height="60"
              style={{
                backgroundColor: "#f1f1f1",
                borderRadius: "10px",
                padding: "5px",
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Chip
                variant="outlined"
                label={connected ? "Connected" : "Connect"}
                color={connected ? "blarSuccess" : "primary"}
                sx={{
                  cursor: connected ? "default" : "pointer",
                  pointerEvents: connected ? "none" : "auto",
                }}
                onClick={() => (connected ? null : handleAddIntegration())}
              />
            )}
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "10px", marginBottom: "5px" }}>
            <Typography variant="h6">{name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">{description}</Typography>
          </Grid>
        </Grid>
      </Paper>
      <AddApiKeyModal
        open={openAddApiKey}
        setOpen={setOpenAddApiKey}
        integration={integration}
        setCreatedObject={setCreatedObject}
      />
    </>
  );
};

export default IntegrationCard;
