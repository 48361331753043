import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React from "react";
import useStyles from "./styles/ContinueButton";

interface ConnectButtonProps {
  continueOnboarding: () => void;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
}

const ContinueButton: React.FC<ConnectButtonProps> = ({
  continueOnboarding,
  loading,
  disabled,
  className,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root + (className ? ` ${className}` : "")}>
      <LoadingButton
        variant="contained"
        color="secondary"
        loading={loading}
        style={{ fontSize: "20px", margin: 0 }}
        onClick={continueOnboarding}
        disabled={disabled}
      >
        Continue
      </LoadingButton>
    </Box>
  );
};

export default ContinueButton;
