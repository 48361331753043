import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Menu,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { Repository } from "@/interfaces/IBackendOutputs";
import { AxiosError, AxiosResponse } from "axios";
import { git_list_repos, git_repository_info } from "@/services/Blar/Integrations";
import { Context } from "@/contexts/ContextProvider";
import { LoadingButton } from "@mui/lab";
import GitHubIcon from "@mui/icons-material/GitHub";
import { CreateRepoParams } from "@/pages/Settings/components/Repos/types";
import { create_repo } from "@/services/Blar/Repo_graph";
import BranchSelector from "./BranchSelector";

interface RepositoryBranchSelectorProps {
  setHasRepo: React.Dispatch<React.SetStateAction<boolean>>;
}

const RepositoryBranchSelector: React.FC<RepositoryBranchSelectorProps> = ({ setHasRepo }) => {
  const { showMessage } = useContext(Context);
  const [repositories, setRepositories] = useState<Repository[]>([]);
  const [selectedRepository, setSelectedRepository] = useState<string>("");
  const [selectedBranches, setSelectedBranches] = useState<string[]>([]);
  const [loadingRepos, setLoadingRepos] = useState(false);
  const [isRepositoryTooBig, setIsRepositoryTooBig] = useState(false);
  const [repositoryPage, setRepositoryPage] = useState(1);
  const [hasMoreRepos, setHasMoreRepos] = useState(true);

  const fetchNextDataRepository = useCallback(async (page: number) => {
    try {
      setLoadingRepos(true);
      const repositoriesResponse: AxiosResponse = await git_list_repos(page);
      if (repositoriesResponse.status === 200) {
        setRepositories((prev) => [...prev, ...repositoriesResponse.data.results]);
        setRepositoryPage(repositoriesResponse.data.page + 1);
        setHasMoreRepos(repositoriesResponse.data.total_count > repositories.length);
      }
    } catch (error) {
      showMessage("error", "Failed to get repositories");
    } finally {
      setLoadingRepos(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repositories]);

  useEffect(() => {
    fetchNextDataRepository(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRepositoryChange = async (
    event: SelectChangeEvent<string>
  ) => {
    const value = event.target.value;
    
    if (!value) {
      setSelectedRepository("");
      setSelectedBranches([]);
      setIsRepositoryTooBig(false);
      return;
    }
    
    const index = parseInt(value, 10);
    setSelectedRepository(value);
    setSelectedBranches([]);
    
    const repositoryName = repositories[index].fullName;
    
    const org = repositoryName.split("/")[0];
    const repo = repositoryName.split("/")[1];
    try {
      const repositoryInfoRequest = await git_repository_info(org, repo);
      const repositoryInfo = repositoryInfoRequest.data;
      const size = repositoryInfo.size;
      // check if the repository is too big, 2GB
      if (size > 2000000) {
        console.warn("⚠️ Repository too big:", size);
        setIsRepositoryTooBig(true);
      } else {
        setIsRepositoryTooBig(false);
      }
    } catch (error) {}
  };

  const handleAddRepository = async () => {
    const selectedIndex = parseInt(selectedRepository, 10);
    const allReady = selectedIndex >= 0 && 0 < selectedBranches.length && selectedBranches.length < 3;

    if (allReady) {
      try {
        setLoadingRepos(true);
        if (selectedBranches.length === 1) {
          selectedBranches.push(selectedBranches[0]);
        }
        const addRepoParams: CreateRepoParams = {
          repo_name: repositories[selectedIndex].fullName,
          git_url: repositories[selectedIndex].url,
          main_branch: selectedBranches[0],
          development_branch: selectedBranches[1],
        };
        const createRepoResponse = await create_repo(addRepoParams);
        if (createRepoResponse.status === 201) {
          showMessage("success", "Repository added successfully");
          setHasRepo(true);
        }
        setSelectedRepository("");
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 400 && error.response) {
            showMessage("error", error.response.data.error);
            return
          }
          showMessage("error", "An unexpected error occurred");
          return
        }
        showMessage("error", "Failed to add repository");
      } finally {
        setLoadingRepos(false);
      }
    }
  };

  return (
    <Box width={"100%"}>
      <FormControl fullWidth>
        <InputLabel id="repository_select" style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Repository</InputLabel>
        <Select
          id="repository_select"
          color="secondary"
          value={selectedRepository}
          label="Repository"
          placeholder="Select a repository"
          onChange={handleRepositoryChange}
          MenuProps={{
            PaperProps: {
              onScroll: (event: React.UIEvent<HTMLElement>) => {
                const bottom =
                  event.currentTarget.scrollHeight ===
                  event.currentTarget.scrollTop + event.currentTarget.clientHeight;
                if (bottom && hasMoreRepos && !loadingRepos) {
                  fetchNextDataRepository(repositoryPage);
                }
              },
              style: { maxHeight: 300, overflowY: "auto" },
            },
          }}
        >
          <MenuItem value="">
            <em>Select a repository</em>
          </MenuItem>
          {repositories.length > 0 ? repositories.map((repo, index) => (
            <MenuItem key={index} value={index.toString()}>
              {repo.fullName}
            </MenuItem>
          )) : <MenuItem disabled>No repositories found</MenuItem>}
        </Select>
        <br/>
      </FormControl>
      
      {isRepositoryTooBig && (
        <Typography variant="body1" color="error">
          Currently, we don't support repositories bigger than 2GB. Please select another one.
        </Typography>
      )}
      
      {selectedRepository !== "" && !isRepositoryTooBig && (
        <BranchSelector
          repositoryFullName={repositories[parseInt(selectedRepository, 10)].fullName}
          selectedBranches={selectedBranches}
          onBranchesChange={setSelectedBranches}
          disabled={isRepositoryTooBig}
        />
      )}

      <LoadingButton
        variant="contained"
        color="secondary"
        startIcon={<GitHubIcon />}
        style={{ fontSize: "20px", margin: "16px 0" }}
        onClick={handleAddRepository}
        loading={loadingRepos}
        disabled={
          selectedRepository === "" ||
          selectedBranches.length === 0 ||
          isRepositoryTooBig
        }
      >
        Add Repository
      </LoadingButton>
    </Box>
  );
};

export default RepositoryBranchSelector;