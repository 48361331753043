import { Skeleton } from "@mui/material";
import TableWithActions from "@/components/Tables/TableWithActions";

const ChecklistTableSkeleton = () => {
  const skeletonRows = Array(5).fill(null).map((_, index) => ({
    Status: { 
      type: "react" as const, 
      content: <Skeleton variant="circular" width={24} height={24} /> 
    },
    Name: { 
      type: "react" as const, 
      content: <Skeleton variant="text" width={200} /> 
    },
    Progress: { 
      type: "react" as const, 
      content: <Skeleton variant="text" width={60} /> 
    },
    "Time to Fix": { 
      type: "react" as const, 
      content: <Skeleton variant="text" width={100} /> 
    },
    Impact: { 
      type: "react" as const, 
      content: <Skeleton variant="rounded" width={80} height={24} /> 
    },
  }));

  return (
    <TableWithActions 
      tableRows={skeletonRows}
      defaultTitles={["Status", "Name", "Progress", "Time to Fix", "Impact"]}
      loading={true}
      onClick={() => {}}
      maxHeight="calc(100vh - 200px)"
    />
  );
};

export default ChecklistTableSkeleton; 