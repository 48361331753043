import { Box, Grid, Typography, LinearProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useStyles from "./styles/Redirect";
import { Context } from "@/contexts/ContextProvider";
import { useLocation } from "react-router-dom";
import { integrations } from "@/components/Integrations/integrations";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import handleGithubIntegration from "../Settings/components/Integrations/handleIntegrations/handleGithub";
import handleSentryIntegration from "../Settings/components/Integrations/handleIntegrations/handleSentry";
import { handleBitbucketIntegration } from "../Settings/components/Integrations/handleIntegrations/handleBitbucket";

interface Props {}

const Redirect: React.FC<Props> = ({}) => {
  const classes = useStyles();
  const location = useLocation();
  const { showMessage } =
    useContext(Context);

  const queryParams = new URLSearchParams(location.search);
  const sourceIntegration = queryParams.get("integration")?.toLowerCase();
  const integration = integrations.filter(
    (integration) => integration.dbName === sourceIntegration
  )[0];

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const handleIntegration = async () => {
      if (sourceIntegration === "github") {
        await handleGithubIntegration(showMessage, queryParams);
      } else if (sourceIntegration === "sentry") {
        await handleSentryIntegration(showMessage, queryParams);
      } else if (sourceIntegration === "bitbucket") {
        await handleBitbucketIntegration(showMessage, queryParams);
      }
    };
    
    const startIntegrationProcess = async () => {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 90 ? 90 : prevProgress + 1.5
        );
      }, 30);
    
      await handleIntegration();
    
      clearInterval(timer);
      setProgress(100);
    };
    
    // Call the async function to start the process
    startIntegrationProcess();
  }, []);

  return (
    <Box className={classes.root}>
      {integration ? (
        <Box>
          <Box className={classes.logosContainer}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={4} className={classes.gridItem}>
                <img
                  className={classes.logo}
                  src={integration.logo}
                  alt={integration.name}
                />
              </Grid>
              <Grid item xs={4} className={classes.gridItem}>
                <DoubleArrowIcon sx={{ fontSize: "100px" }} />
              </Grid>
              <Grid item xs={4} className={classes.gridItem}>
                <img
                  className={classes.logo}
                  src="/logos/BlueLogo.svg"
                  alt="Blar"
                />
              </Grid>
            </Grid>
          </Box>
          <Typography fontSize={24} component={"h1"}>
            Redirecting to Blar
          </Typography>
          <br />
          <Box className={classes.loadingBarContainer}>
            <LinearProgress
              sx={{ height: "100%", borderRadius: "10px" }}
              variant="determinate"
              color="secondary"
              value={progress}
            />
          </Box>
        </Box>
      ) : (
        <Box className={classes.logosContainer}>
          <Typography fontSize={24} component={"h1"}>
            Redirecting...
          </Typography>
          <br />
          <LinearProgress
            sx={{ height: "100%" }}
            variant="determinate"
            color="secondary"
            value={progress}
          />
        </Box>
      )}
    </Box>
  );
};

export default Redirect;
