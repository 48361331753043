import { CheckIntegration } from "@/interfaces/IIntegration";
import {
  AddApiKeyParams,
  SentryOauthParams,
  AddSentryWorkaroundParams,
} from "../../pages/Settings/components/Integrations/types";
import { blarClient } from "./Client";
import { AxiosResponse } from "axios";
import { CodeErrorPut } from "@/pages/Chat/components/types";

const PER_PAGE = 10;

export const check_integration_exists = async ({
  source,
}: CheckIntegration) => {
  return blarClient.request("get", `/integrations/${source}`);
};

export const check_code_git_integration_exists = async () => {
  return blarClient.request("get", `/integrations/git/check`);
};

export const save_api_key = async (params: AddApiKeyParams) => {
  return blarClient.request("post", `/integrations/create`, params);
};

export const save_sentry_workaround = async (
  params: AddSentryWorkaroundParams
) => {
  return blarClient.request("post", `/integrations/sentry/create`, params);
};

export const list_integrations = async () => {
  return blarClient.request("get", `/integrations/list`);
};

export const delete_integration = async (id: string) => {
  return blarClient.request("post", `/integrations/delete`, { id });
};

export const slack_oauth = async () => {
  return blarClient.request("get", `/integrations/slack/oauth/`);
};

export const sentry_oauth = async (payload: SentryOauthParams) => {
  return blarClient.request("post", `/integrations/sentry/oauth`, payload);
};

export const bitbucket_oauth = async (code: string): Promise<any> => {
  return blarClient.request(
    "post",
    `/integrations/git/oauth`,
    {
      code,
      git_provider: "bitbucket",
    }
  );
}

export const github_app_installation = async (payload: {
  installation_id: string;
  code: string;
}) => {
  return blarClient.request(
    "post",
    `/integrations/git/app_installation`,
    payload
  );
};

export const git_list_repos = async (page: number) => {
  return blarClient.request("get", `/integrations/git/repositories`, null, {
    page,
    per_page: PER_PAGE,
  });
};

export const git_list_repo_branches = async (repository: string, page: number) => {
  return blarClient.request(
    "get",
    `/integrations/git/repositories/branches`,
    undefined,
    {
      repository,
      page,
      per_page: PER_PAGE
    }
  );
};

export const git_repository_info = async (
  org: string,
  repo: string
): Promise<AxiosResponse> => {
  return blarClient.request(
    "get",
    `/integrations/git/repositories/${org}/${repo}`
  );
};

export const code_error_put = async (payload: CodeErrorPut) => {
  return blarClient.request("put", `/integrations/code-error`, payload);
};
