import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { ChecklistContextProvider, useChecklistContext } from "./contexts/ChecklistContext";
import { CriteriaContextProvider, useCriteriaContext } from "./contexts/CriteriaContext";
import TableWithActions from "@/components/Tables/TableWithActions";
import { Criteria, Step } from "./types";
import SyncIcon from '@mui/icons-material/Sync';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import StepStatusIcon, { StepStatusType } from "./components/StepStatusIcon";
import RemediateButton from "./components/RemediateButton";
import RemediateDrawer from "./components/RemediateDrawer";
import { calculateLastRefresh } from "./helpers";
import ReactMarkdown from 'react-markdown'
import { get_criteria_data, run_steps_for_criteria_status, get_criteria_status_by_id } from "@/services/Blar/Checklist";
import { dataToCriteriaData } from "./helpers";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import CriteriaSkeleton from "./components/skeletons/CriteriaSkeleton";

const CriteriaPageContent: React.FC = () => {
  const navigate = useNavigate();
  const [formattedRefreshTime, setFormattedRefreshTime] = useState<string>('')
  const { selectedRepo } = useChecklistContext();
  const { criteriaId, repoName } = useParams();
  const { criteriaData, setCriteriaData, setCriteriaChecksInProgress } = useCriteriaContext();
  const [isLoading, setIsLoading] = useState(true);
  const [disabledButton, setDisabledButton] = useState<boolean>(true);

  const getCriteriaData = async () => {
    if (!selectedRepo) return null;
    return get_criteria_data(selectedRepo.repo_id, criteriaId as string)
      .then(response => dataToCriteriaData(response.data, criteriaId as string))
      .catch(error => {
        console.error("Error fetching criteria data:", error);
        return null;
      });
  }

  const getCriteriaStatus = async () => {
    if (!selectedRepo) return;
    let status;
    try {
      const response = await get_criteria_status_by_id(selectedRepo.repo_id, criteriaId as string);
      status = response.data.status;
    } catch (error) {
      console.error("Error fetching criteria status:", error);
      setIsLoading(false);
      setDisabledButton(false);
      return;
    }

    if (status === 'in_progress') {
      setDisabledButton(true);
      setCriteriaChecksInProgress(true);
      return;
    }

    setDisabledButton(false);
    setCriteriaChecksInProgress(false);

    const data = await getCriteriaData();
    if (data) setCriteriaData(data);
    setIsLoading(false);
  }

  useEffect(() => {
    if (!selectedRepo) return;
    setIsLoading(true);
    getCriteriaData().then(data => {
      if (data) setCriteriaData(data);
      setIsLoading(false);
    });
  }, [selectedRepo, setCriteriaData]);

  useEffect(() => {
    if (criteriaData) {
      setFormattedRefreshTime(calculateLastRefresh(criteriaData.last_refresh))
    }
  }, [criteriaData])

  useEffect(() => {
    if (!selectedRepo) return;
    const interval = setInterval(() => {
      getCriteriaStatus()
    }, 1000);
    return () => clearInterval(interval);
  }, [selectedRepo]);

  const formatTableRows = (steps: Step[] | undefined) => {
    if (!steps) return [];
    return steps.map(step => ({
      "Status": { type: "react" as const, content: <StepStatusIcon status={step.status as StepStatusType} step_id={step.id} /> },
      "Task": { type: "string" as const, content: step.name },
      "": {
        type: "react" as const,
        content: <RemediateButton step={step} />
      },
    }));
  };

  const handleBackNavigation = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(`/checklist/${repoName}`);
    }
  };

  if (isLoading) {
    return <CriteriaSkeleton />;
  }

  return (
    <Box width="100%" margin="auto" height="100%" textAlign="left" padding={8} display="flex" flexDirection="column" gap={8}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" alignItems="center" gap={2}>
          <IconButton onClick={handleBackNavigation} sx={{ padding: 0 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" fontWeight={"medium"}>{criteriaData?.name}</Typography>
        </Box>
        <ReactMarkdown>
          {criteriaData?.description || ''}
        </ReactMarkdown>
      </Box>
      <Box>
        <Typography variant="h5">Tests</Typography>
        <Box marginX={2}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Typography variant="body1">Last refreshed {formattedRefreshTime}</Typography>
            <IconButton onClick={async () => {
              setDisabledButton(true);
              try {
                await run_steps_for_criteria_status(criteriaId as string, selectedRepo!.repo_id);
              } catch (error) {
                console.error('Error running steps:', error);
                setDisabledButton(false);
              }
            }} disabled={disabledButton}>
              {disabledButton ? <CircularProgress size={"1em"} /> : <SyncIcon />}
            </IconButton>
          </Box>
          <TableWithActions
            tableRows={formatTableRows(criteriaData?.steps)}
            defaultTitles={["Status", "Task", ""]}
            loading={!criteriaData}
          />
        </Box>
      </Box>
      <RemediateDrawer />
    </Box>
  );
};

const CriteriaPage: React.FC = () => {
  return (
    <ChecklistContextProvider>
      <CriteriaContextProvider>
        <CriteriaPageContent />
      </CriteriaContextProvider>
    </ChecklistContextProvider>
  );
};

export default CriteriaPage;