import { Box, Button, Link, Stack, Typography } from "@mui/material";
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from "react-router-dom";

export default function PullRequestPaywall() {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
            }}
        >
            <Typography variant="h4" gutterBottom>
                Enforce Best Practices in Every Pull Request
            </Typography>
            <Typography variant="h5" align="justify" gutterBottom>
                Review with Context, Fix with Confidence
            </Typography>
            <Typography
                variant="body1"
                align="justify"
                gutterBottom
                sx={{ whiteSpace: "pre-line" }}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    <CheckIcon />
                    <Typography variant="body1" gutterBottom>
                        PR Summaries – Quickly understand the intent behind each pull request.
                    </Typography>
                </Stack>

                <Stack direction="row" spacing={1} alignItems="center">
                    <SearchIcon />
                    <Typography variant="body1" gutterBottom>
                        Code Insights – Get actionable feedback on every line of code.
                    </Typography>
                </Stack>

                <Stack direction="row" spacing={1} alignItems="center">
                    <SquareFootOutlinedIcon />
                    <Typography variant="body1" gutterBottom>
                        Automated Reviews – Ensure every pull request meets your standards.
                    </Typography>
                </Stack>
            </Typography>

            <Button onClick={() => { navigate("/subscription") }} variant="contained" sx={{ mt: 2 }}
            >
                Subscribe
            </Button>
            <Link href="https://calendly.com/jose-wsa/30min" sx={{ mt: 2 }}>
                <Typography variant="body2">Contact a Founder</Typography>
            </Link>
        </Box >
    );
}