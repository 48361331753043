import { Box, Typography, Container } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { useChecklistContext } from "../contexts/ChecklistContext";
import TableWithActions from "@/components/Tables/TableWithActions";
import { useEffect, useState } from "react";
import StatusIcon, { StatusType } from "./StatusIcon";
import ImpactChip, { ImpactType } from "./ImpactChip";
import { ChecklistItem } from "../types";
import { useCriteriaContext, CriteriaContextProvider } from "../contexts/CriteriaContext";
import { list_repo_checklist_items } from "@/services/Blar/Checklist";
import { dataToChecklistItem, formatDuration } from "../helpers";
import ChecklistTableSkeleton from "./skeletons/ChecklistTableSkeleton";

const Checklist = () => {
  const { selectedRepo } = useChecklistContext();
  const { handleSelectCriteria } = useCriteriaContext();
  const [checklistItems, setChecklistItems] = useState<ChecklistItem[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchChecklist = async () => {
    setLoading(true);
    try {
      const checklistResult = await list_repo_checklist_items(selectedRepo!.repo_id);
      setChecklistItems(dataToChecklistItem(checklistResult.data));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (selectedRepo) {
      fetchChecklist();
    }
  }, [selectedRepo]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (selectedRepo) {
        fetchChecklist();
      }
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  const formatTableRows = (items: ChecklistItem[]) => {
    return items.map(item => ({
      Status: { type: "react" as const, content: <StatusIcon status={item.status as StatusType} /> },
      Name: { type: "string" as const, content: item.name },
      Progress: { type: "number" as const, content: item.percentage },
      "Time to Fix": { type: "string" as const, content: formatDuration(item.timeToFix) },
      Impact: { type: "react" as const, content: <ImpactChip impact={item.impact as ImpactType} /> },
    }));
  };

  const defaultTitles = ["Status", "Name", "Progress", "Time to Fix", "Impact"];


  // TODO: Remove this
  if (!selectedRepo) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Typography variant="h6" fontFamily="Ubuntu Sans Mono"></Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4, px: 4, marginTop: 12.5 }}>
      {loading ? (
        <ChecklistTableSkeleton />
      ) : (
        <TableWithActions 
          tableRows={formatTableRows(checklistItems)}
          defaultTitles={defaultTitles}
          loading={loading}
          onClick={(index) => handleSelectCriteria(checklistItems[index].id)}
          maxHeight="calc(100vh - 200px)"
        />
      )}
    </Container>
  );
};

const RepoChecklist = () => {
  return (
    <CriteriaContextProvider>
      <Routes>
        <Route path="/:repoName" element={<Checklist />} />
        <Route path="/" element={<Checklist />} />
      </Routes>
    </CriteriaContextProvider>
  );
};

export default RepoChecklist;