import React, { useRef, useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import FileStructure from "./components/WikiFileStructure";
import FileEditor from "./components/FileEditor";
import TemplateGallery from "./components/TemplateGallery";
import ResizableLayout from "@/components/Layout/ResizebleLayout";
import { WikiProvider, useWiki } from "./context/WikiContext";
import { useSearchParams, useNavigate } from "react-router-dom";

const defaultGuideContent = `## Let's add some guidelines to the wiki.

1. Add some guidelines; we will consider them in the pull request reviews.
2. Double-click the name at the top right to modify it.
3. Don't forget to save your changes using the button at the top right.`;

const WikiPageContent: React.FC = () => {
  const { selectedNode, inputFileContent, structure, handleCreateFile } = useWiki();
  const containerRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const [fileCreated, setFileCreated] = useState(false);

  useEffect(() => {
    const isOnboarding = searchParams.get('onboarding') === 'true';
    const repoName = searchParams.get('repo');

    if (isOnboarding && repoName && structure.length > 0 && !fileCreated) {
      // Find the repository folder (root folder with the same name)
      const repoFolder = structure.find(node => 
        node.type === 'folder' && node.name === repoName
      );

      if (repoFolder) {
        // Create a new file in the repository folder
        handleCreateFile("Hello World!", repoFolder.id, defaultGuideContent);
        setFileCreated(true);
      }
    }
  }, [searchParams, structure, handleCreateFile, fileCreated]);

  const sidebarContent = <FileStructure />;

  const renderMainContent = () => {
    if (!selectedNode) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Typography variant="h6">
            Select a file to view its contents
          </Typography>
        </Box>
      );
    }

    if (selectedNode.type === 'folder') {
      return <TemplateGallery />;
    }

    if (selectedNode.type === 'file' && inputFileContent !== null) {
      return <FileEditor key={selectedNode.id} isOnboarding={searchParams.get('onboarding') === 'true'} />;
    }

    return null;
  };

  const mainContent = renderMainContent();

  return (
    <Box ref={containerRef} width="100%" height="100%">
      <ResizableLayout
        sidebarContent={sidebarContent}
        mainContent={mainContent}
        initialWidth={400}
        minWidth={300}
        maxWidth={500}
        forceWidth={400}
      />
    </Box>
  );
};

const WikiPage: React.FC = () => {
  return (
    <WikiProvider>
      <WikiPageContent />
    </WikiProvider>
  );
};

export default WikiPage;
