import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, IconButton, Container, Alert } from "@mui/material";
import { useState, useEffect } from "react";
import { getSummary } from "@/services/Blar/Starter";
import { AccordionLayout } from "./components/AccordionLayout";
import { summaryDataMap } from "./helpers";
import { CriteriasAccordion } from "./components/CriteriasAccordion";
import { PullRequestAccordion } from "./components/PullRequestAccordion";
import { GuidelinesAccordion } from "./components/GuidelinesAccordion";
import AccordionSkeleton from "./components/skeletons/AccordionSkeleton";

export type CriteriaStatus = {
  status: 'failed' | 'completed' | 'pending' | 'in_progress';  // assuming these are the possible statuses
  created_at: string;
  updated_at: string;
  total_steps: number;
  completed_steps: number;
  criteria__id: number;
  criteria__name: string;
}

export type RepoCriterias = {
  repo_name: string;
  criterias_statuses: CriteriaStatus[];
}

export type RepoPullRequest = {
  repo_name: string;
  pull_request_feature_flag: boolean;
}

export type RepoGuidelines = {
  repo_name: string;
  wiki_step_status: boolean;
}

const StarterPage = () => {
  const [criteriasDetails, setCriteriasDetails] = useState<RepoCriterias[]>([]);
  const [pullRequestDetails, setPullRequestDetails] = useState<RepoPullRequest[]>([]);
  const [guidelinesDetails, setGuidelinesDetails] = useState<RepoGuidelines[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        setError(null);
        setLoading(true);
        const response = await getSummary();
        const { criteriasData, pullRequestData, guidelinesData } = summaryDataMap(response);
        setCriteriasDetails(criteriasData);
        setPullRequestDetails(pullRequestData);
        setGuidelinesDetails(guidelinesData);
      } catch (error) {
        console.error("Error fetching summary:", error);
        setError("Failed to load the guide data. Please try refreshing the page or contact support.");
      } finally {
        setLoading(false);
      }
    };
    fetchSummary();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ 
      py: 4, // padding vertical instead of height
      px: 4  // padding horizontal
    }}>
      <Box sx={{ 
        width: "100%",
        mb: 2,
    }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" fontWeight={"medium"} sx={{ color: "white", textAlign: "left" }}>
          Let's take control of your tech debt
        </Typography>
      </Box>
      <Box sx={{ 
        backgroundColor: "#161B22", 
        p: 4, 
        borderRadius: "10px", 
        mb: 4, 
        border: "1px solid #30363d"
      }}>
        <Typography variant="h6" sx={{ color: "white", mb: 2, textAlign: "left" }}>
          A short guide to Blar
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {error ? (
            <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
          ) : loading ? (
            <AccordionSkeleton />
          ) : (
            <>
              <CriteriasAccordion criteriasDetails={criteriasDetails} />
              <PullRequestAccordion pullRequestDetails={pullRequestDetails} />
              <GuidelinesAccordion guidelinesDetails={guidelinesDetails} />
            </>
          )}
        </Box>
      </Box>
    </Box>
    </Container>
  );
};

export default StarterPage;