import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Chip,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { Context } from "@/contexts/ContextProvider";
import { git_list_repo_branches } from "@/services/Blar/Integrations";

interface BranchSelectorProps {
  repositoryFullName: string;
  selectedBranches: string[];
  onBranchesChange: (branches: string[]) => void;
  disabled?: boolean;
}

const BranchSelector: React.FC<BranchSelectorProps> = ({
  repositoryFullName,
  selectedBranches,
  onBranchesChange,
  disabled = false
}) => {
  const { showMessage } = useContext(Context);
  const [branches, setBranches] = useState<string[]>([]);
  const [loadingBranches, setLoadingBranches] = useState(false);
  const [branchesPage, setBranchesPage] = useState(1);
  const [hasMoreBranches, setHasMoreBranches] = useState(true);

  const fetchNextDataBranches = async (repo: string, page: number) => {
    try {
      setLoadingBranches(true);
      const branchesResponse = await git_list_repo_branches(repo, page);
      if (branchesResponse.status === 200) {
        setBranches((prev) => {
          const updatedBranches = [...prev, ...branchesResponse.data.results];
          setHasMoreBranches(branchesResponse.data.total_count > updatedBranches.length);
          return updatedBranches;
        });
        setBranchesPage(branchesResponse.data.page + 1);
      }
    } catch (error) {
      showMessage("error", "Failed to get branches");
    } finally {
      setLoadingBranches(false);
    }
  };

  useEffect(() => {
    setBranchesPage(1);
    setBranches([]);
    if (repositoryFullName) {
      fetchNextDataBranches(repositoryFullName, 1);
    }
  }, [repositoryFullName]);

  const handleBranchChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    if (Array.isArray(value)) {
      if (value.length <= 2) {
        onBranchesChange(value);
      }
    }
  };

  return (
    <>
      <Typography variant="body2" color="textSecondary" style={{ marginTop: 8 }}>
        Select one or two branches that we will be watching and reviewing the code on the PRs made to them.
      </Typography>
      <br/>
      <FormControl fullWidth>
        <InputLabel id="branches_select" style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
          Branches
        </InputLabel>
        <Select
          id="branches_select"
          multiple
          value={selectedBranches}
          label="Branches"
          onChange={handleBranchChange}
          disabled={disabled}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value, index) => (
                <Chip 
                  key={index} 
                  label={value}
                  color={"primary"}
                  variant="outlined"
                  size="small"/>
              ))}
            </Box>
          )}
          MenuProps={{
            PaperProps: {
              onScroll: (event: React.UIEvent<HTMLElement>) => {
                const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
                const isAtBottom = Math.abs(scrollHeight - (scrollTop + clientHeight)) < 1;
                if (isAtBottom && hasMoreBranches && !loadingBranches) {
                  fetchNextDataBranches(repositoryFullName, branchesPage);
                }
              },
              style: { maxHeight: 300 },
            },
          }}
        >
          {branches.map((branch) => (
            <MenuItem 
              key={branch} 
              value={branch}
              disabled={selectedBranches.length >= 2 && !selectedBranches.includes(branch)}
            >
              {branch}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default BranchSelector; 