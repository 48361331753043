import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";

import formatters from "@/components/Tables/formatters";
import { TablePaginationOptions, TableRowData } from "@/components/Tables/types";
import { LinearProgress, Link } from "@mui/material";

import { StyledTableRow, StyledTableHeadRow, StyledTableHeadCell, StyledTableCell } from "@/components/Tables/Styles/TableWithActions";

type TableWithActionsProps = {
    tableRows: TableRowData[];
    defaultTitles: string[];
    loading: boolean;
    onDelete?: (index: number) => void;
    onEdit?: (index: number) => void;
    onClick?: (index: number) => void;
    tablePagination?: TablePaginationOptions;
    maxHeight?: string | number;
};

const TableWithActions: React.FC<TableWithActionsProps> = ({
    tableRows,
    defaultTitles,
    loading,
    onClick,
    tablePagination,
    maxHeight = '400px',
}) => {
    const theme = useTheme();

    const tableTitles = tableRows[0] ? Object.keys(tableRows[0]) : defaultTitles;
    const visibleTitles = tableTitles.filter(
        (title) => tableRows[0]?.[title]?.type !== "hidden"
    );


    const handleRowClick = (index: number) => {
        if (onClick) {
            onClick(index);
        }
    };

    return (
        <>
            <TableContainer
                component={Paper}
                sx={{
                    padding: "0",
                    maxHeight: maxHeight,
                    overflowY: 'auto',
                    border: '1px solid #FFFFFF4D',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Table size="small" sx={{ minWidth: 700 }} aria-label="customized table" stickyHeader>
                    <TableHead>
                        <StyledTableHeadRow>
                            {visibleTitles.map((title) => (
                                <StyledTableHeadCell key={title}>
                                    {title}
                                </StyledTableHeadCell>
                            ))}
                        </StyledTableHeadRow>
                    </TableHead>
                    <TableBody>
                        {loading && (
                            <StyledTableRow>
                                <StyledTableCell colSpan={visibleTitles.length + 1}>
                                    <LinearProgress />
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                        {!loading &&
                            tableRows.map((row, index) => (
                                <StyledTableRow
                                    theme={theme}
                                    key={index}
                                    onClick={onClick ? () => handleRowClick(index) : undefined}
                                >
                                    {tableTitles.map(
                                        (title) =>
                                            row[title].type !== "hidden" && (
                                                <StyledTableCell key={title}>
                                                    {formatters[row[title].type]
                                                        ? formatters[row[title].type](row[title])
                                                        : row[title].content}
                                                </StyledTableCell>
                                            )
                                    )}
                                </StyledTableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer >
            {tablePagination && (
                <TablePagination
                    component="div"
                    count={tablePagination.count}
                    rowsPerPageOptions={[10, 20, 30]}
                    page={tablePagination.page}
                    rowsPerPage={tablePagination.rowsPerPage}
                    onPageChange={tablePagination.handlePagination}
                    onRowsPerPageChange={tablePagination.handleChangeRowsPerPage}
                />
            )
            }
        </>
    );
};

export default TableWithActions;
