import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { serverSignIn, serverSignUp } from "../../services/Blar/Auth";
import { Context } from "@/contexts/ContextProvider";
import {
  TextField,
  Box,
  Grid,
  Alert,
  CardContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { AxiosError } from "axios";
import { get_invitation } from "@/services/Blar/Company";
import useStyles from "./styles/AuthForm";

interface AuthFormValues {
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  authError?: string;
}

interface AuthFormProps {
  continueOnboarding?: () => void;
}

const AuthInvitationForm: React.FC<AuthFormProps> = ({
  continueOnboarding,
}: AuthFormProps) => {
  const [companyName, setCompanyName] = useState("");
  const [validToken, setValidToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [tokenError, setTokenError] = useState<string | null>(null);

  const { signIn } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    const fetchInvitation = async () => {
      if (token) {
        try {
          const response = await get_invitation(token);
          if (response.status === 200) {
            const invitation = response.data;
            setCompanyName(invitation.company_name);
            setValidToken(invitation.token);
          } else {
            setTokenError("Invalid invitation token.");
            setLoading(false);
          }
        } catch (error) {
          setTokenError("Invitation token has expired or is invalid.");
          setLoading(false);
        }
      } else {
        setTokenError("No invitation token provided.");
        setLoading(false);
      }
    };

    fetchInvitation();
  }, [token]);

  const formik = useFormik<AuthFormValues>({
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: {
      firstName: "",
      lastName: "",
      companyName: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/\d/, "Password must contain at least one number")
        .required("Required"),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      try {
        let authResponse;
        await serverSignUp({
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          password: values.password,
          token: validToken,
        });

        authResponse = await serverSignIn(values.email, values.password);
        await signIn(authResponse.data.access, authResponse.data.refresh);
        const redirectPath = new URLSearchParams(location.search).get(
          "redirect"
        );
        navigate(redirectPath || "");
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 400) {
          if (axiosError.response.data) {
            const errorData = axiosError.response.data as {
              detail: { [key: string]: string[] };
            };
            const errorDataDetails = errorData.detail;
            const errors: string[] = [];
            for (const key of Object.keys(errorDataDetails)) {
              if (errorDataDetails.hasOwnProperty(key)) {
                errors.push(`${key}: ${errorDataDetails[key]}`);
              }
            }
            const errorString: string = errors.reduce(
              (acc, curr) => acc + curr + "\n",
              ""
            );
            setErrors({ authError: errorString });
          }
        } else {
          setErrors({
            authError:
              "The username or password you entered is incorrect. Please try again.",
          });
        }
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (tokenError) {
      setTimeout(() => {
        navigate("/login"); // Redirect to login after showing error message
      }, 3000); // Redirect after 3 seconds
    }
  }, [tokenError, navigate]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      {loading ? (
        <CircularProgress />
      ) : tokenError ? (
        <Box display="block" textAlign="center">
          <img src="/logos/BlueLogo.svg" alt="Blar" />
          <Typography
            variant="h3"
            align="center"
            fontSize={50}
            sx={{
              lineHeight: "40px",
              marginBottom: "20px",
            }}
          >
            {tokenError}
          </Typography>
          <Typography
            variant="h5"
            align="center"
            sx={{
              marginBottom: "20px",
            }}
          >
            You will be redirected to the login page shortly.
          </Typography>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <Typography
            variant="h3"
            align="center"
            fontSize={50}
            sx={{
              lineHeight: "40px",
              marginBottom: "20px",
            }}
          >
            Welcome!
          </Typography>
          <img src="/logos/BlueLogo.svg" alt="Blar" />
          <Typography
            variant="h3"
            align="center"
            fontSize={50}
            sx={{
              lineHeight: "40px",
              marginBottom: "20px",
            }}
          >
            You've been invited to join {companyName}
          </Typography>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              maxWidth: 400,
              padding: "32px",
              margin: "16px 0", // Added margin for spacing
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              component="form"
              onSubmit={formik.handleSubmit}
              noValidate
            >
              <Grid
                container
                rowSpacing={2}
                direction="column"
                sx={{ width: "100%" }}
                className={classes.form}
              >
                <Grid item sx={{ width: "100%" }}>
                  <TextField
                    fullWidth
                    InputProps={{
                      sx: {
                        borderRadius: "10px",
                      },
                    }}
                    placeholder="Name"
                    type="text"
                    id="firstName"
                    {...formik.getFieldProps("firstName")}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </Grid>
                <Grid item sx={{ width: "100%" }}>
                  <TextField
                    fullWidth
                    InputProps={{
                      sx: {
                        borderRadius: "10px",
                      },
                    }}
                    placeholder="Last Name"
                    type="text"
                    id="lastName"
                    {...formik.getFieldProps("lastName")}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </Grid>
                <Grid item sx={{ width: "100%" }}>
                  <TextField
                    fullWidth
                    InputProps={{
                      sx: {
                        borderRadius: "10px",
                      },
                    }}
                    placeholder="Email"
                    type="text"
                    id="email"
                    {...formik.getFieldProps("email")}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.errors.email}
                  />
                </Grid>
                <Grid item sx={{ width: "100%" }}>
                  <TextField
                    fullWidth
                    InputProps={{
                      sx: {
                        borderRadius: "10px",
                      },
                    }}
                    placeholder="Password"
                    type="password"
                    id="password"
                    {...formik.getFieldProps("password")}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </Grid>
                <Grid item>
                  {formik.errors.authError && (
                    <Alert severity="error">{formik.errors.authError}</Alert>
                  )}
                </Grid>
                <Grid item container direction="column" sx={{ width: "100%" }}>
                  <LoadingButton
                    loading={formik.isSubmitting}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{ fontSize: "20px", borderRadius: "10px" }}
                  >
                    Sign Up
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Box>
      )}
    </Box>
  );
};

export default AuthInvitationForm;
