import React from "react";
import { Box } from "@mui/material";
import ResizableLayout from "@/components/Layout/ResizebleLayout";
import ReposList from "./components/RepoList";
import { ChecklistContextProvider } from "./contexts/ChecklistContext";
import RepoChecklist from "./components/RepoChecklist";

const ChecklistPageContent: React.FC = () => {
  return (
    <Box width="100%" height="100%">
      <ResizableLayout
        sidebarContent={<ReposList />}
        mainContent={<RepoChecklist />}
        initialWidth={350}
        minWidth={350}
        maxWidth={350}
        forceWidth={350}
      />
    </Box>
  );
};

const ChecklistPage: React.FC = () => {
  return (
    <ChecklistContextProvider>
      <ChecklistPageContent />
    </ChecklistContextProvider>
  );
};

export default ChecklistPage;
