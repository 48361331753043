const summaryDataMap = (data: any) => {
  const criteriasData = data.map((item: any) => {
    return {
      repo_name: item.repo_name,
      criterias_statuses: item.criteria_status.map((status: any) => ({
        status: status.status,
        created_at: status.created_at,
        updated_at: status.updated_at,
        total_steps: status.total_steps,
        completed_steps: status.completed_steps,
        criteria__id: status.criteria__id,
        criteria__name: status.criteria__name
      }))
    }
  })

  const pullRequestData = data.map((item: any) => {
    return {
      repo_name: item.repo_name,
      pull_request_feature_flag: item.pull_request_feature_flag
    }
  })
  const guidelinesData = data.map((item: any) => {
    return {
      repo_name: item.repo_name,
      wiki_step_status: item.wiki_step_status
    }
  })

  return {
    criteriasData,
    pullRequestData,
    guidelinesData
  }
}

export { summaryDataMap }