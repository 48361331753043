import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    width: "100%",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  container: {
    display: "flex", 
    flexDirection: "column", 
    alignItems: "center", 
    padding: theme.spacing(4)
  },
  successAlert: {
    width: '100%', 
    maxWidth: '800px', 
    marginBottom: theme.spacing(2),
    '& .MuiAlert-message': {
      width: '100%',
      textAlign: 'center'
    }
  },
  paper: {
    borderColor: theme.palette.divider,
    width: '100%',
    maxWidth: '800px',
    padding: theme.spacing(3),
  },
  headerSection: {
    marginBottom: theme.spacing(4), 
    textAlign: 'left'
  },
  title: {
    fontWeight: 500, 
    marginBottom: theme.spacing(1)
  },
  subtitle: {
    marginBottom: theme.spacing(3)
  },
  repoSelect: {
    marginBottom: theme.spacing(4)
  },
  templatesGrid: {
    overflow: 'auto', 
    maxHeight: 'calc(100vh - 400px)',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: theme.spacing(2),
  },
  templatePaper: {
    padding: theme.spacing(2),
    transition: 'all 0.2s',
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderRadius: theme.spacing(2),
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      borderColor: theme.palette.primary.main,
    },
    '&.disabled': {
      cursor: 'not-allowed',
      '&:hover': {
        backgroundColor: 'transparent',
        borderColor: theme.palette.divider,
      }
    }
  },
  iconBox: {
    width: 40,
    height: 40,
    borderRadius: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.action.selected,
    '&.disabled': {
      filter: 'grayscale(100%)'
    }
  },
  icon: {
    width: 24,
    height: 24,
  },
  templateName: {
    fontWeight: 500,
    '&.disabled': {
      color: 'rgba(255, 255, 255, 0.5)'
    }
  },
  helperText: {
    marginTop: theme.spacing(2), 
    textAlign: 'center'
  },
  buttonContainer: {
    marginTop: theme.spacing(4), 
    width: '100%', 
    maxWidth: '800px'
  }
}));

export default useStyles;