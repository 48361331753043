import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";


interface StyledTableRowProps {
    onClick?: () => void;
}

const StyledTableRow = styled(TableRow)<StyledTableRowProps>(({ theme, onClick }) => ({
    height: "50px",
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    ...(onClick && {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.background.default,
        }
    })
}));

const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
    height: "50px",
}));

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    textAlign: "left",
    fontFamily: "Ubuntu Sans Mono",
    borderColor: "#FFFFFF4D",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: 0,
    zIndex: 1,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: "left",
    fontFamily: "Ubuntu Sans Mono",
    borderColor: "#FFFFFF4D",
}));


export { StyledTableRow, StyledTableHeadRow, StyledTableHeadCell, StyledTableCell };