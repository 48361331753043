import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface AccordionLayoutProps {
  title: React.ReactNode;
  description: React.ReactNode;
  children: React.ReactNode;
}

export const AccordionLayout = ({ title, description, children }: AccordionLayoutProps) => {
  return (
    <Accordion 
      defaultExpanded={true}
      sx={{ 
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderRadius: "5px",
        border: "1px solid #30363d",
        mb: 2,
        '&:before': { display: 'none' }
      }}
    >
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
        sx={{ 
          padding: 2,
          '& .MuiAccordionSummary-content': { margin: 0 }
        }}
      >
        <Box sx={{ textAlign: "left", display: "flex", flexDirection: "column"}}>
          {typeof title === 'string' ? (
            <Typography variant="h6" sx={{ color: "white" }}>
              {title}
            </Typography>
          ) : (
            title
          )}
          {typeof description === 'string' ? (
            <Typography sx={{ color: "rgba(255, 255, 255, 0.7)" }}>
              {description}
            </Typography>
          ) : (
            description
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 2, py: 1 }}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}