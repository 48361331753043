import React, { useContext, useEffect } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { resend_verification_email } from "@/services/Blar/Auth";
import { Context } from "@/contexts/ContextProvider";

const VerifyEmail: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Parse query params for status messages (success/error)
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");
  const { showMessage, refreshAccessToken } = useContext(Context);

  useEffect(() => {
    const handleVerification = async () => {
      if (status === "success") {
        await refreshAccessToken();
        navigate("/onboarding");
      }
    };

    handleVerification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleResendEmail = async () => {
    try {
      await resend_verification_email();
      showMessage("success", "Verification email sent successfully!");
    } catch (error) {
      showMessage(
        "error",
        "Failed to send verification email. Please try again."
      );
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bgcolor="background.default"
      p={2}
    >
      <Box
        bgcolor="background.paper"
        padding={4}
        borderRadius={3}
        boxShadow={4}
        width="100%"
        maxWidth={450}
        textAlign="center"
      >
        {/* Logo */}
        <Grid container justifyContent="center" alignItems="center" mb={3}>
          <Grid item>
            <img
              src="/logos/BlueLogo.svg"
              alt="Blar Logo"
              style={{
                maxWidth: "120px",
                display: "block",
                margin: "0 auto",
              }}
            />
          </Grid>
        </Grid>

        {/* Title */}
        <Typography variant="h5" gutterBottom fontWeight="bold">
          Verify Your Email Address
        </Typography>

        {/* Status Messages */}
        {status === "success" ? (
          <Typography
            variant="body1"
            color="success.main"
            mb={3}
            fontWeight="bold"
          >
            🎉 Your email has been verified successfully!
          </Typography>
        ) : status === "invalid" ? (
          <Typography variant="body1" mb={3} fontWeight="bold">
            ⚠️ Verification failed. Please try again.
          </Typography>
        ) : (
          <Typography
            variant="body1"
            mb={3}
            color="text.secondary"
            lineHeight={1.5}
          >
            We’ve sent a verification link to your email address. Please check
            your inbox and click the link to verify your account.
          </Typography>
        )}

        {/* Buttons */}
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleResendEmail}
          sx={{ mb: 2, py: 1.5 }}
        >
          Resend Verification Email
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={() => navigate("/login")}
          sx={{ py: 1.5 }}
        >
          Back to Home
        </Button>
        {/* Contact Information */}
        <Typography
          variant="body2"
          color="text.secondary"
          mt={3}
          lineHeight={1.5}
        >
          If you’re having any problems, feel free to contact us at{" "}
          <Typography
            component="a"
            href="mailto:hello@blar.io"
            color="primary"
            sx={{ fontWeight: "bold", textDecoration: "none" }}
          >
            hello@blar.io
          </Typography>
          .
        </Typography>
      </Box>
    </Box>
  );
};

export default VerifyEmail;
