import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { check_code_git_integration_exists } from "@/services/Blar/Integrations";
import { list_repos } from "@/services/Blar/Repo_graph";
import { Context } from "@/contexts/ContextProvider";
import LoadingTypography from "./components/LoadingTypography";
import RepositoryBranchSelector from "@/components/Selectors/RepositoryBranchSelector";
import ContinueButton from "@/components/Buttons/ContinueButton";
import useStyles from "./styles/Integrations";

interface CoreIntegrationProps {
  continueOnboarding: () => void;
}

const AddRepository: React.FC<CoreIntegrationProps> = ({
  continueOnboarding,
}) => {
  const classes = useStyles();
  const { showMessage } = useContext(Context);
  const [isGitConnected, setIsGitConnected] = useState<
    boolean | null | "pending"
  >(null);
  const [hasRepo, setHasRepo] = useState(false);
  const [loadingGit, setLoadingGit] = useState(false);
  const [loadingRepos, setLoadingRepos] = useState(false);

  const checkGitIntegrationExists = async (): Promise<boolean | "pending"> => {
    try {
      setLoadingGit(true);

      const res = await check_code_git_integration_exists();
      setIsGitConnected(res.data.is_integrated);
    } catch (error) {
      showMessage("error", "Failed to get code git integration");
    } finally {
      setLoadingGit(false);
    }

    return false;
  };

  useEffect(() => {
    if (isGitConnected) {
      checkIfReposExists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGitConnected]);

  const checkIfReposExists = async () => {
    try {
      setLoadingRepos(true);

      const repoRequest = await list_repos();
      const repositories = repoRequest.data.results;

      if (repositories.length > 0) {
        setHasRepo(true);
      }
    } catch (error) {
      showMessage("error", "Failed to get repositories");
    } finally {
      setLoadingRepos(false);
    }
  };

  useEffect(() => {
    checkGitIntegrationExists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.root}>
      <LoadingTypography
        text="Select your first repository"
        loading={loadingRepos || loadingGit}
        stepCompleted={hasRepo}
      />
      <Typography className={classes.loggerToolBox}>
        We currently support Python, JavaScript, TypeScript, and Ruby (Beta).
        Let's get started!
      </Typography>
      <Box className={classes.loggerToolBox}>
        {isGitConnected === true && !hasRepo && (
          <RepositoryBranchSelector setHasRepo={setHasRepo} />
        )}
      </Box>
      <ContinueButton
        continueOnboarding={continueOnboarding}
        disabled={!(isGitConnected && hasRepo)}
      />
    </Box>
  );
};

export default AddRepository;
