import React, { useEffect, useState } from 'react';
import { Drawer, Box, Typography, IconButton, Tabs, Tab } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useCriteriaContext } from '../contexts/CriteriaContext';
import RemediateContent from './RemediateContent';
import { get_remediate_step, get_step_status_details } from '@/services/Blar/Checklist';
import RemediateDrawerSkeleton from './skeletons/RemediateDrawerSkeleton';

interface RemediateStep {
  id: string;
  name: string;
  content: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`remediation-tabpanel-${index}`}
      aria-labelledby={`remediation-tab-${index}`}
      {...other}
      sx={{
        height: 'calc(100% - 48px)', // Subtract tabs height
        overflow: 'hidden'
      }}
    >
      {value === index && (
        <Box
          sx={{
            p: 2,
            height: '100%',
            overflow: 'auto'
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

const RemediateDrawer: React.FC = () => {
  const { selectedStep, setSelectedStep } = useCriteriaContext();
  const [tabValue, setTabValue] = useState(0);
  const [remediateStep, setRemediateStep] = useState<RemediateStep | null>(null);
  const [stepStatusDetails, setStepStatusDetails] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setSelectedStep(null);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const fetchRemediateStep = async (stepId: string) => {
    const response = await get_remediate_step(stepId);
    return response.data
  };

  const fetchStepStatusDetails = async (stepStatusId: string) => {
    const response = await get_step_status_details(stepStatusId);
    return response.data.details
  };

  useEffect(() => {
    if (selectedStep) {
      setIsLoading(true);
      Promise.all([
        fetchRemediateStep(selectedStep.id),
        fetchStepStatusDetails(selectedStep.id)
      ]).then(([stepData, statusData]) => {
        setRemediateStep(stepData);
        setStepStatusDetails(statusData);
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, [selectedStep]);

  return (
    <Drawer
      anchor="right"
      open={selectedStep !== null}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: {
            xs: '100%',
            sm: '60%'
          },
          minWidth: 300,
          borderLeft: '1px solid #FFFFFF4D',
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
          }
        }
      }}
    >
      {selectedStep && (
        isLoading ? <RemediateDrawerSkeleton /> : (
          <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
            <Box p={2} flexShrink={0}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h5" fontWeight={500} fontFamily="Ubuntu Sans Mono">
                  How to Remediate
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>

              <Typography variant="body1" mb={3}>
                {selectedStep.name}
              </Typography>
            </Box>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', flexShrink: 0 }}>
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="remediation tabs">
                <Tab label="Steps" />
                <Tab label="Explanation" />
              </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
              <RemediateContent content={remediateStep?.content as string} />
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <Box p={2} whiteSpace={'pre-wrap'}>
                {stepStatusDetails}
              </Box>
            </TabPanel>

          </Box>
        )
      )}
    </Drawer>
  );
};

export default RemediateDrawer;
