import { Box, Grid, Container, Typography } from "@mui/material";

import IntegrationTable from "./components/Integrations";
import ReposTable from "./components/Repos";
import UsersTable from "./components/Users";
import { useSearchParams } from "react-router-dom";

const Settings = () => {
  const [searchParams] = useSearchParams();
  const selectedIntegration = searchParams.get("integration");
  return (
    <Container maxWidth="lg" sx={{ p: 4 }}>
      <Typography variant="h4" fontWeight={"medium"} sx={{ mb: 4 }} textAlign={"left"}>Settings</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <IntegrationTable selectedIntegration={selectedIntegration} />
        </Grid>
        <Grid item xs={12}>
          <ReposTable />
        </Grid>
        <Grid item xs={12}>
          <UsersTable />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Settings;
