import axios, {
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosResponse,
  AxiosRequestConfig,
} from 'axios';
import { ContextProps } from '@/contexts/ContextProvider';
import Cookies from 'universal-cookie';
import { access } from 'fs';

class BlarClient {
  private static instance: BlarClient;
  private axiosInstance: AxiosInstance | undefined = undefined;
  private API_BASE_URL = process.env.REACT_APP_API_URL!;
  private cookies = new Cookies();
  private signIn?: ContextProps['signIn'];
  private signOut?: ContextProps['signOut'];
  private refreshAccessToken?: ContextProps['refreshAccessToken'];

  constructor() {
    if (BlarClient.instance) {
      return BlarClient.instance;
    }

    this.axiosInstance = axios.create({
      baseURL: this.API_BASE_URL,
      timeout: 120000,
    });

    this.axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const access = this.cookies.get('access');
        if (access) {
          config.headers['Authorization'] = `Bearer ${access}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.response.use((response: AxiosResponse) => {
      return response;
    }, this.handleError.bind(this));

    BlarClient.instance = this;
  }

  init(signInFunction: ContextProps['signIn'], signOutFunction: ContextProps['signOut'], refreshAccessFunction: ContextProps['refreshAccessToken']): void {
    this.signIn = signInFunction;
    this.signOut = signOutFunction;
    this.refreshAccessToken = refreshAccessFunction;
  }

  request(
    method: 'get' | 'post' | 'put' | 'delete' | 'patch' | 'sse',
    url: string,
    params?: any,
    queryParams?: any,
    headers?: any
  ): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = {
      headers,
      params: queryParams,
      paramsSerializer: {
        indexes: null,
      },
    };
    switch (method) {
      case 'get':
        return this.axiosInstance!.get(url, config);
      case 'post':
        return this.axiosInstance!.post(url, params, config);
      case 'put':
        return this.axiosInstance!.put(url, params, config);
      case 'patch':
        return this.axiosInstance!.patch(url, params, config);
      case 'delete':
        return this.axiosInstance!.delete(url, config);
      case 'sse':
        config.headers = {
          'Content-Type': 'application/json',
          Accept: 'text/event-stream',
        };
        config.responseType = 'stream';
        config.adapter = 'fetch';
        return this.axiosInstance!.post(url, params, config);
      default:
        throw new Error('No valid method');
    }
  }

  private async handleError(error: any): Promise<any> {
    const originalRequest = error.config;
    const errorList = [401, 403];
    if (
      error.response &&
      errorList.includes(error.response.status) &&
      !originalRequest._retry &&
      originalRequest.url !== 'user/signin'
    ) {
      
      const errorReason = error.response.data.detail;
      
      if (errorReason === 'You do not have permission to perform this action.') {
        return Promise.reject(error);
      }

      originalRequest._retry = true;
      const refreshToken = this.cookies.get('refresh');

      if (!refreshToken) {
        return Promise.reject(error);
      }

      try {
        const newAccessToken = await this.refreshAccessToken!();
        this.signIn!(newAccessToken!, refreshToken);
        // Retry the original request
        originalRequest.headers['Authorization'] = 'Bearer ' + newAccessToken;
        return this.axiosInstance!(originalRequest);
      } catch (refreshError: any) {
        console.error('Error refreshing token', refreshError);
        if (refreshError.response && refreshError.response.status === 401) {
          if (this.signOut) this.signOut();
        }
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
}

export const blarClient = new BlarClient();
