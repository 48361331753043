import { bitbucket_oauth } from "@/services/Blar/Integrations";
import { AlertColor } from "@mui/material";
import { URLSearchParams } from "url";

const REDIRECT_TIMEOUT = 3 * 1000;

export function redirectToBitbucketApp() {
  const bitbucketAuthUrl = process.env.REACT_APP_BITBUCKET_APP_URL!;

  window.location.href = bitbucketAuthUrl;
}

export const handleBitbucketIntegration = async (
  showMessage: (type: AlertColor, message: string) => void,
  searchParams: URLSearchParams
) => {
  try {
    const code = searchParams.get("code");

    const response = await bitbucket_oauth(code!);
    const { message, redirect_url } = response.data;
    showMessage("success", message);
    setTimeout(() => (window.location.href = redirect_url), REDIRECT_TIMEOUT);
  } catch (error) {
    showMessage("error", "Failed to connect Bitbucket");
  }
};

export const handleRedirectToBitbucketApp = async (
  showMessage: (type: AlertColor, message: string) => void
) => {
  showMessage("info", "Redirecting to Bitbucket for authentication");
  setTimeout(() => redirectToBitbucketApp(), REDIRECT_TIMEOUT);
  return;
};
