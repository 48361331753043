import React, { useContext } from "react";
import { Container } from "@mui/material";
import ReposTable from "./ReposTable";

import { Context } from "@/contexts/ContextProvider";


import PullRequestPaywall from "./PullRequestPaywall";

const PullRequest: React.FC = () => {
    const { companyTier } = useContext(Context);
    return (
        companyTier === 0 
        ? <PullRequestPaywall /> 
        : <Container maxWidth="lg" sx={{ p: 4 }}>
            <ReposTable />
        </Container>
    );
};

export default PullRequest;
