import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { DataType } from "./types";
import { format } from "date-fns";
import { Link } from "@mui/material";

const formatDate = (value: DataType) => {
    const dateFormat = value.options?.dateFormat || "dd MMMM yyyy";
    const dateContent = value.content as string;
    return format(new Date(dateContent), dateFormat);
};

const formatters: { [key in DataType["type"]]: (value: any) => any } = {
    string: (value) => value.content,
    number: (value) => Number.isInteger(value.content) ? value.content.toString() : value.content.toFixed(2),
    date: (value) => formatDate(value),
    boolean: (value) =>
        value.content ? (
            <CheckCircleIcon sx={{ color: "#1a7f64" }} />
        ) : (
            <CloseIcon sx={{ color: "#ef4146" }} />
        ),
    hidden: () => null,
    url: (value) => (
        <Link href={value.content} target="_blank" rel="noopener noreferrer">
            {value.content}
        </Link>
    ),
    react: (value) => value.content,
};

export default formatters;