import { Box, Typography, Card, Button, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Repo } from "@/pages/Settings/components/Repos/types";
import RepoProgress from "./RepoProgress";
import SearchBar from "./SearchBar"
import { useEffect, useState } from "react";
import { list_repo_checklist_percentage } from "@/services/Blar/Checklist";
import { useChecklistContext } from "../contexts/ChecklistContext";
import { useParams, useNavigate } from "react-router-dom";
import { formatRepoName } from "../helpers";
import RepoProgressSkeleton from "./skeletons/RepoProgressSkeleton";
import RefreshIcon from '@mui/icons-material/Refresh';

export interface RepoChecklistPercentage {
  repo_id: string;
  repo_status_percentage: number;
  name: string;
}

const ReposList = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [repoList, setRepoList] = useState<RepoChecklistPercentage[]>([]);
  const [filteredRepoList, setFilteredRepoList] = useState<RepoChecklistPercentage[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { selectedRepo, setSelectedRepo } = useChecklistContext();
  const repoNameFromUrl = useParams().repoName;

  async function fetchReposChecklistPercentageData() {
    try {
      const reposResult = (await list_repo_checklist_percentage());
      setError(null);
      return reposResult.data;
    } catch (err: any) {
      const errorMessage = err.response?.status === 500
        ? "Server error occurred. Please try again later."
        : err.response?.data?.message || "Failed to fetch repositories";
      setError(errorMessage);
      return [];
    }
  }

  const handleRetry = async () => {
    try {
      setIsLoading(true);
      const repos = await fetchReposChecklistPercentageData();
      setRepoList(repos);

      const filtered = repos.filter((repo: RepoChecklistPercentage) =>
        repo.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredRepoList(filtered);

    } catch (err) {
      console.error("Error retrying fetch:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateSelectedRepo = (repo: RepoChecklistPercentage) => {
    setSelectedRepo(repo);
    const repoNameForUrl = formatRepoName(repo.name, false).toLowerCase().replace(/_/g, '-');
    navigate(`/checklist/${repoNameForUrl}`);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const repos = await fetchReposChecklistPercentageData();
        setRepoList(repos);
        setFilteredRepoList(repos);

        if (!selectedRepo) {
          if (repoNameFromUrl) {
            const repo = repos.find((r: Repo) => {
              const repoNameWithoutOrg = formatRepoName(r.name, false).toLowerCase();
              const urlRepoName = repoNameFromUrl.toLowerCase();
              return repoNameWithoutOrg === urlRepoName ||
                repoNameWithoutOrg.replace(/_/g, '-') === urlRepoName;
            });
            if (repo) {
              updateSelectedRepo(repo);
            }
          } else if (repos.length > 0) {
            // Select the first repo if no repo is selected and no repo is specified in URL
            updateSelectedRepo(repos[0]);
          }
        }
      } catch (err) {
        console.error("Error fetching repos:", err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const filtered = repoList.filter(repo =>
      repo.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredRepoList(filtered);
  }, [searchQuery, repoList]);

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  return (
    <Box sx={{ padding: "20px", textAlign: "left", display: "flex", flexDirection: "column", gap: "10px" }}>
      <Typography variant="h5" sx={{ fontWeight: "bold" }} fontFamily={"Ubuntu Sans Mono"} marginBottom={2}>Repos</Typography>
      <SearchBar onChange={handleSearch} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {isLoading ? (
          <>
            <RepoProgressSkeleton />
            <RepoProgressSkeleton />
            <RepoProgressSkeleton />
          </>
        ) : error ? (
          <Card sx={{ p: 2, width: "100%", display: "flex", marginX: "auto", flexDirection: "column", alignItems: "center", textAlign: "center", border: `1px solid red`, borderRadius: "10px" }}>
            <Typography variant="body1" gutterBottom>
              {error}
            </Typography>
            <IconButton
              color="primary"
              onClick={handleRetry}
              sx={{ mt: 1, mb: 0 }}
            >
              <RefreshIcon />
            </IconButton>
          </Card>
        ) : repoList.length === 0 ? (
          <Card sx={{ p: 2, width: "100%", display: "flex", marginX: "auto", flexDirection: "column", alignItems: "center", textAlign: "center", border: `1px solid ${theme.palette.primary.main}`, borderRadius: "10px" }}>
            <Typography variant="body1" gutterBottom>
              No repositories found.
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate("/settings")}
              sx={{ mt: 1, mb: 0, width: "100%" }}
            >
              Add repositories
            </Button>
          </Card>
        ) : (
          filteredRepoList.map((repo) => (
            <RepoProgress key={repo.name} repo={repo} />
          ))
        )}
      </Box>
    </Box>
  );
};

export default ReposList;
