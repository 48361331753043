import React, { useState, useEffect } from "react";
import { Box, Typography, Paper, useTheme, CircularProgress, Select, MenuItem, FormControl, InputLabel, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getTemplates, getTemplateContent, createFileFromTemplate } from "@/services/Blar/Wiki";
import { list_repos } from "@/services/Blar/Repo_graph";
import ContinueButton from "@/components/Buttons/ContinueButton";
import { Context } from "@/contexts/ContextProvider";
import useStyles from "./styles/Guidelines";

interface Template {
  name: string;
  path: string;
}

interface Repository {
  repo_id: string;
  name: string;
}

interface RepoPaginatedResponse {
  results: Repository[];
  count: number;
}

interface WriteGuidelinesProps {
  continueOnboarding: () => void;
}

const WriteGuidelines: React.FC<WriteGuidelinesProps> = ({ continueOnboarding }) => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [repositories, setRepositories] = useState<Repository[]>([]);
  const [selectedRepo, setSelectedRepo] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [creatingFile, setCreatingFile] = useState<string | null>(null);
  const [guidelineCreated, setGuidelineCreated] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const { showMessage } = React.useContext(Context);
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [templatesResponse, reposResponse] = await Promise.all([
          getTemplates(),
          list_repos()
        ]);
        setTemplates(templatesResponse);
        const repoData = reposResponse.data as RepoPaginatedResponse;
        setRepositories(repoData.results);
        
        // Set default repository if there's only one
        if (repoData.results.length === 1) {
          setSelectedRepo(repoData.results[0].repo_id);
        }

        // Check if returning from wiki with successful save
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get('from') === 'wiki' && searchParams.get('saved') === 'true') {
          setGuidelineCreated(true);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatTemplateName = (name: string) => {
    return name.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const handleTemplateClick = async (template: Template) => {
    if (creatingFile || !selectedRepo) return;
    
    try {
      setCreatingFile(template.path);
      const response = await createFileFromTemplate(template.name, selectedRepo);
      setGuidelineCreated(true);
    } catch (error: any) {
      console.error('Error creating guideline:', error);
      if (error.response?.data?.error) {
        // Handle specific error messages from the API
        showMessage("error", error.response.data.error);
      } else {
        showMessage("error", "Failed to create guideline");
      }
    } finally {
      setCreatingFile(null);
    }
  };

  const handleCreateFromScratch = () => {
    const selectedRepoData = repositories.find(repo => repo.repo_id === selectedRepo);
    if (selectedRepoData) {
      navigate(`/wiki?onboarding=true&repo=${selectedRepoData.name}&return=/onboarding?from=wiki&step=4`);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      {guidelineCreated && (
        <Alert 
          severity="success" 
          className={classes.successAlert}
        >
          Congratulations! You've successfully set up a guideline file in our wiki. Let's continue with the next step!
        </Alert>
      )}
      
      <Paper className={classes.paper}>
        <Box className={classes.headerSection}>
          <Typography variant="h4" className={classes.title}>
            Write Your Guidelines
          </Typography>
          <Typography variant="body1" color="text.secondary" className={classes.subtitle}>
            Select a repository and choose a template to get started, or create your own from scratch.
          </Typography>

          <FormControl fullWidth className={classes.repoSelect}>
            <InputLabel id="repo-select-label">Select Repository</InputLabel>
            <Select
              labelId="repo-select-label"
              value={selectedRepo}
              label="Select Repository"
              onChange={(e) => setSelectedRepo(e.target.value)}
            >
              {repositories.map((repo) => (
                <MenuItem key={repo.repo_id} value={repo.repo_id}>
                  {repo.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box className={classes.templatesGrid}>
          <Paper
            key="scratch"
            elevation={0}
            onClick={selectedRepo ? handleCreateFromScratch : undefined}
            className={`${classes.templatePaper} ${!selectedRepo ? 'disabled' : ''}`}
          >
            <Box className={`${classes.iconBox} ${!selectedRepo ? 'disabled' : ''}`}>
              {creatingFile === 'scratch' ? (
                <CircularProgress size={24} />
              ) : (
                <img src="/icons/edit_square.svg" alt="Create" className={classes.icon} style={{ opacity: selectedRepo ? 1 : 0.5 }} />
              )}
            </Box>
            <Typography 
              variant="subtitle1" 
              className={`${classes.templateName} ${!selectedRepo ? 'disabled' : ''}`}
              color={selectedRepo ? 'primary' : undefined}
            >
              Create your own
            </Typography>
          </Paper>

          {templates.map((template) => (
            <Paper
              key={template.path}
              elevation={0}
              onClick={selectedRepo ? () => handleTemplateClick(template) : undefined}
              className={`${classes.templatePaper} ${(!selectedRepo || creatingFile) ? 'disabled' : ''}`}
            >
              <Box className={`${classes.iconBox} ${!selectedRepo ? 'disabled' : ''}`}>
                {creatingFile === template.path ? (
                  <CircularProgress size={24} />
                ) : (
                  <img 
                    src="/icons/insert_drive.png" 
                    alt="Template" 
                    className={classes.icon}
                    style={{ opacity: selectedRepo ? 1 : 0.5 }} 
                  />
                )}
              </Box>
              <Typography 
                variant="subtitle1" 
                className={`${classes.templateName} ${(!selectedRepo || creatingFile) ? 'disabled' : ''}`}
              >
                {formatTemplateName(template.name)}
              </Typography>
            </Paper>
          ))}
        </Box>

        <Typography variant="body2" color="text.secondary" className={classes.helperText}>
          {selectedRepo ? 'You can later modify the selected template.' : 'Please select a repository first.'}
        </Typography>
      </Paper>
      
      <Box className={classes.buttonContainer}>
        <ContinueButton
          continueOnboarding={continueOnboarding}
          disabled={false}
        />
      </Box>
    </Box>
  );
};

export default WriteGuidelines;
