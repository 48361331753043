import React, { useContext, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import ChecklistIcon from '@mui/icons-material/Checklist';
import TuneIcon from "@mui/icons-material/Tune";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import SideBarButton from "../Buttons/SideBarButton";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import AnchorMenu from "../AnchorMenu/AnchorMenu";
import MoreMenu from "./MoreMenu";
import { useTheme } from "@mui/material/styles";
import { Context } from "@/contexts/ContextProvider";
import useIntegrationSources from "./IntegrationsList";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CallMergeIcon from '@mui/icons-material/CallMerge';

type SidebarProps = {
  style?: React.CSSProperties;
};

function getInitialSelected(location: any) {
  const queryParams = new URLSearchParams(location.search);
  const triggerType = queryParams.get("triggerType");
  if (location.pathname === "/chat") {
    return triggerType || "";
  }
  if (location.pathname === "/settings")
    return "settings";
  return "";
}

const Sidebar: React.FC<SidebarProps> = ({ style }) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const sources = useIntegrationSources();
  const [selected, setSelected] = React.useState(getInitialSelected(location));
  const [settingsAnchorEl, setSettingsAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [isExpanded, setIsExpanded] = React.useState(false);
  useEffect(() => {
    setSelected(getInitialSelected(location));
  }, [location]);

  const handleCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickChecklist = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setSelected("checklist");
    navigate("/checklist");
  };

  const handleClickPullRequests = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setSelected("pull-requests");
    navigate("/pull-requests");
  };

  const handleClickWiki = (
    event: React.MouseEvent<HTMLElement>,
    value: any
  ) => {
    setSelected(value);

    navigate("/wiki");
  };

  const handleClickSettingsMenu = (
    event: React.MouseEvent<HTMLElement>,
    value: any
  ) => {
    setSettingsAnchorEl(event.currentTarget);
  };

  const handleClickStarter = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setSelected("starter");
    navigate("/starter");
  };


  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isExpanded ? 180 : 50,
        flexShrink: 0,
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.standard,
        }),
        [`& .MuiDrawer-paper`]: {
          width: isExpanded ? 180 : 50,
          boxSizing: "border-box",
          borderRadius: 0,
          backgroundColor: "blarBlack",
          borderRight: "1px solid",
          borderColor: theme.palette.divider,
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.standard,
          }),
        },
        ...style,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 50,
        }}
      >
        <Box
          component="img"
          src="/logos/BlarIconWhite.svg"
          alt="Logo"
          sx={{ height: 25, cursor: "pointer" }}
          onClick={() => navigate("/home")}
        />
        {isExpanded && (
          <Typography mt={0.25} variant="h6" sx={{ color: "white", ml: 1 }}>
            Blar
          </Typography>
        )}
      </Box>

      <Box
        mt={4}
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          marginTop: "20px",
          flexGrow: 1,
          width: '100%',
        }}
      >
        <Stack
          spacing={2}
          direction="column"
          useFlexGap
          flexWrap="wrap"
          sx={{ width: '100%', px: 1 }}
        >
          <SideBarButton
            isExpanded={isExpanded}
            value="starter"
            selected={selected === "starter"}
            handleClick={handleClickStarter}
            tooltip="Start your Blar journey"
            label="Starter"
          >
            <HomeOutlinedIcon sx={{ width: 20, height: 20, color: "primary" }} />
          </SideBarButton>
          <SideBarButton
            isExpanded={isExpanded}
            value="checklist"
            selected={selected === "checklist"}
            handleClick={handleClickChecklist}
            tooltip="Let's review your practices"
            label="Checklist"
          >
            <ChecklistIcon
              sx={{ width: 20, height: 20, color: "primary" }}
            />
          </SideBarButton>
          <SideBarButton
            isExpanded={isExpanded}
            value="pull-requests"
            selected={Boolean(settingsAnchorEl) || selected === "pull-requests"}
            handleClick={handleClickPullRequests}
            label="Pull Requests"
            tooltip="Configure your pull requests review settings"
          >
            <CallMergeIcon
              sx={{ width: 20, height: 20, color: "primary" }}
            />
          </SideBarButton>

          <SideBarButton
            isExpanded={isExpanded}
            value="wiki"
            selected={selected === "wiki"}
            handleClick={handleClickWiki}
            label="Wiki"
            tooltip="Create and edit your guidelines in the Wiki"
          >
            <MenuBookOutlinedIcon
              sx={{ width: 20, height: 20, color: "primary" }}
            />
          </SideBarButton>
        </Stack>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          mb: 1,
          width: '100%',
        }}
      >
        <Stack
          spacing={2}
          direction="column"
          useFlexGap
          flexWrap="wrap"
          sx={{ width: '100%', px: 1 }}
        >
          <SideBarButton
            isExpanded={isExpanded}
            value="settings"
            selected={Boolean(settingsAnchorEl) || selected === "settings"}
            handleClick={handleClickSettingsMenu}
            label="Settings"
          >
            <TuneIcon sx={{ width: 20, height: 20, color: "primary" }} />
          </SideBarButton>
        </Stack>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 50,
        }}
      >
        <SideBarButton
          isExpanded={isExpanded}
          value="collapse"
          selected={isExpanded}
          handleClick={handleCollapse}
          alignment="right"
        >
          {isExpanded ? <ChevronLeftIcon sx={{ width: 20, height: 20, color: "primary" }} /> : <ChevronRightIcon sx={{ width: 20, height: 20, color: "primary" }} />}
        </SideBarButton>
      </Box>
      <AnchorMenu
        title="More"
        anchorEl={settingsAnchorEl}
        setAnchorEl={setSettingsAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ width: 200, marginLeft: 2 }}
      >
        <MoreMenu setSettingsAnchorEl={setSettingsAnchorEl} />
      </AnchorMenu>
    </Drawer >
  );
};

export default Sidebar;